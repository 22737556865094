import { useEffect, useState } from "react"
import Popup from "./Popup"
import classNames from "classnames"
import HamburgerIcon from "./icons/legacy/HamburgerIcon"

export default function PopupMenu({inverted, flat, small, children, icon, disabled, className, onOpen, onClose, tooltip}) {

  icon = icon || <HamburgerIcon/>

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (visible) {
      onOpen && onOpen()
    } else {
      onClose && onClose()
    }
  }, [visible])

  const buttonClasses = classNames(
    'gray flex button',
    className || '',
    {
      'small':              !!small,
      'no-shadow':          !!inverted || !!flat,
      'no-border':          !!inverted,
      'opacity-50':         !!disabled,
      'cursor-not-allowed': !!disabled,
    }
  )


  function handleButtonClick (e) {
    e.stopPropagation()
    e.preventDefault()

    if (disabled) return

    setVisible(v => !v)
  }

  return <>
    <div className="relative">
      <a className={buttonClasses} onClick={handleButtonClick}>
        {icon}
      </a>

      {tooltip &&
        <div className='hover-parent z-3 pointer-events-none absolute mt-1'>
          <div className='py-1 px-3 text-sm bold white bg-black-transparent rounded nowrap'>
            {tooltip}
          </div>
        </div>
      }
      <Popup visible={visible} onClickOutside={() => setVisible(false)}>
        <div className="p-2 flex flex-col">
          {children}
        </div>
      </Popup>
    </div>

    <style jsx>{`
      .small {
        height: 2rem;
        padding:  0 0.25rem;
      }
    `}</style>
  </>
}
