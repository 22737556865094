/* eslint @next/next/no-img-element: 0 */  // --> OFF

import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { first, flatten, last, max, sort, sortBy, uniq } from '@/lib/array'
import Checkbox from '@/components/Checkbox'
import rpc from '@/src/rpc'
import LoadingSpinner from './LoadingSpinner'
import withLoading from '@/src/withLoading'
import FolderIcon from './icons/FolderIcon'
import useDeviceInfo from '@/src/useDeviceInfo'
import ArrowUturnLeftIcon from './icons/ArrowUturnLeftIcon'
import ArrowUturnRightIcon from './icons/ArrowUturnRightIcon'
import VideoCameraIcon from './icons/VideoCameraIcon'
import PopupMenu from './PopupMenu'
import Modal from './Modal'
import CloseIcon from './icons/CloseIcon'
import CubeIcon from './icons/CubeIcon'
import AlignmentIcon from './icons/AlignmentIcon'
import SwatchIcon from './icons/SwatchIcon'
import UpDownArrowIcon from './icons/UpDownArrowIcon'
import PhotoIcon from './icons/PhotoIcon'
import Popup from './Popup'
import PlusIcon from './icons/PlusIcon'
import TrashIcon from './icons/TrashIcon'
import FileUploader from './FileUploader'
import { titleize } from '@/lib/string'
import FontIcon from './icons/FontIcon'
import EditIcon from './icons/EditIcon'
import { sendMessage } from '@/src/useMessage'


const fontWeights = "Thin ExtraLight Light Regular Medium SemiBold Bold ExtraBold Black".split(' ')

const defaultShadow = {
  blur:    6,
  color:   '#000000',
  opacity: 50,
  x:       0,
  y:       4,
}

const defaultGlow = {
  blur:    6,
  color:   '#FFFFFF',
  opacity: 80,
  x:       0,
  y:       2,
}



export default function ImageEditor({image, onCancel, onSave, mode, org}) {

  const [layers, setLayers] = useState(null)

  const {mac} = useDeviceInfo()

  useEffect(() => {
    loadLayers()

    async function loadLayers () {
      setLayers(
        await rpc('images.getLayers', {imageId: image && image.id})
      )
    }
  }, [image, setLayers])

  const [fonts, setFonts] = useState()

  useEffect(() => {
    loadFonts()

    async function loadFonts () {
      setFonts( await rpc('fonts.backstage') )
    }
  }, [setFonts])


  if (!fonts) return <LoadingSpinner padded/>
  if (!layers) return <LoadingSpinner padded/>

  return <>
    {/* Preload the fonts to prevent delayed rendering */}
    <div className='invisible absolute pointer-events-none top left'>
      { fonts.map(font => (
        <div key={font.uid} style={{fontFamily: font.uid, fontSize: 1}}>
          preload
        </div>
      ))}
    </div>

    <ImageEditorUI
      image={image}
      layers={layers}
      fonts={fonts}
      onSave={onSave}
      onCancel={onCancel}
      mac={mac}
      mode={mode}
      org={org}
    />
  </>
}


function ImageEditorUI ({image, layers: imageLayers, fonts, onCancel, onSave, mac, mode, org}) {

  const [activeLayerIds, setActiveLayerIds] = useState([])
  const [hasEdits, setHasEdits] = useState(false)
  const [layers, setLayers] = useState(imageLayers)
  const [dragging, setDragging] = useState(false)
  const [canvasBrightness, setCanvasBrightness] = useState(0.5)
  const [inactiveOpacity, setInactiveOpacity] = useState(1)
  const [previewBranding, setPreviewBranding] = useState(mode === 'catalog')
  const [shortcutsVisible, setShortcutsVisible] = useState(false)
  const [editingText, setEditingText] = useState(null)

  const selectedLayers = layers.filter(l => activeLayerIds.includes(l.id))

  const firstTextbox = sortBy(layers, '-z').find(l => l.type === 'textbox')
  const [defaultFont, setDefaultFont] = useState( firstTextbox?.metadata?.font || '' )
  const [defaultFontSize, setDefaultFontSize] = useState( firstTextbox?.metadata?.size || 62 )
  const [defaultFontColor, setDefaultFontColor] = useState( firstTextbox?.color || '#FFFFFF' )

  const emptyTextBoxes = layers.filter(l => l.type === 'textbox' && !(l.metadata.text || '').trim())
  const canSave = hasEdits && emptyTextBoxes.length === 0

  const {safari} = useDeviceInfo()

  const clickData = useRef({})
  const dragData = useRef({})
  const resizeData = useRef({})

  const ctrlKeyName = mac ? '⌘' : 'Ctrl'

  const layerColors = layers.map(layer => layer.color).filter(color => color).map(s => s.toUpperCase())
  const defaultColors = [
    '#000000', // Black (
    '#FFFFFF', // White
    '#CC0000', // Red
    '#006633', // Green
    '#0066CC', // Blue
    '#FFCC00', // Yellow
  ]
  const recommendedColors = uniq([...defaultColors, ...layerColors]).slice(0, 12)

  const onlyLogosAndTextboxesSelected = activeLayerIds.every(id => {
    const layer = layers.find(l => l.id === id)
    return layer && (layer.type === 'logo' || layer.type === 'textbox')
  })

  const logoSelected = !!layers.find(l => activeLayerIds.includes(l.id) && l.type === 'logo')
  const textboxesSelected = !!layers.find(l => activeLayerIds.includes(l.id) && l.type === 'textbox')
  const noSelection = activeLayerIds.length === 0
  const inactiveLayerOpacity = (!noSelection && onlyLogosAndTextboxesSelected) ? 1 : inactiveOpacity

  const maintainAspectRatio = !!layers.find(l => activeLayerIds.includes(l.id) && l.type === 'photo')

  const canClickColorToCopy = (logoSelected || textboxesSelected)

  const selectedLayersShadow = selectedLayers.map(l => l.metadata?.shadow)[0]

  let selectedLayersColor = selectedLayers.map(l => l.color)
  selectedLayersColor = uniq(selectedLayersColor)
  if (selectedLayersColor.length === 1) {
    selectedLayersColor = selectedLayersColor[0]
  } else {
    selectedLayersColor = ''
  }

  let selectedLayersAlignment = selectedLayers.map(l => {
    return {
      x: (l.metadata || {}).alignX,
      y: (l.metadata || {}).alignY,
    }
  })
  selectedLayersAlignment = uniq(selectedLayersAlignment)
  if (selectedLayersAlignment.length === 1) {
    selectedLayersAlignment = selectedLayersAlignment[0]
  } else {
    selectedLayersAlignment = {}
  }

  let selectedLayersFontId = selectedLayers.map(l => (l.metadata || {}).font)
  selectedLayersFontId = uniq(selectedLayersFontId)
  if (selectedLayersFontId.length === 1) {
    selectedLayersFontId = selectedLayersFontId[0]
  } else {
    selectedLayersFontId = ''
  }


  let selectedLayersFontSize = selectedLayers.map(l => (l.metadata || {}).size)
  selectedLayersFontSize = uniq(selectedLayersFontSize)
  if (selectedLayersFontSize.length === 1) {
    selectedLayersFontSize = selectedLayersFontSize[0]
  } else {
    selectedLayersFontSize = ''
  }

  const {width: viewportWidth} = useDeviceInfo()

  const imageWidth = image.width
  const maxEditorWidth = viewportWidth ? viewportWidth - 50 : 1024

  const previewWidth  = Math.min(imageWidth / 2, maxEditorWidth)
  const layerWidth    = imageWidth / 8

  const roomForLayersPanel = (previewWidth + layerWidth + 300) > viewportWidth
  const layersPanelVisible = mode === 'template' || mode === 'catalog'
  const layersHidden       = !roomForLayersPanel || !layersPanelVisible

  const imageScale = image.width / previewWidth
  const layerScale = image.width / layerWidth

  const sortedLayers = sortBy(layers, 'z')
  const reversedLayers = layers.slice(0).reverse()
  const hasLogoLayer = layers.find(l => l.type === 'logo')
  const canReverse = layers.filter(l => l.type !== 'logo').length > 1

  const hasVideoLayer = !!layers.find(l => l.type === 'video')
  const canAddLogoLayer = !hasLogoLayer && !hasVideoLayer
  const canAddImageLayer = !hasVideoLayer
  const canAddTextBoxLayer = !hasVideoLayer
  const canEditShadowColor = mode !== 'template' && mode !== 'catalog'

  const layerDragData = useRef({})
  const [layerDragZ, setLayerDragZ] = useState(-1)

  const snapToCenter = useRef(false)
  const ctrlKeyDown  = useRef(false)
  const altKeyDown   = useRef(false)
  const shiftKeyDown = useRef(false)

  useEffect(() => {
    const sizesAndLocations = []
    activeLayerIds.forEach(id => {
      const layer = layers.find(l => l.id === id)
      if (!layer) return

      const {x, y, width, height} = layer
      sizesAndLocations.push( JSON.stringify({x, y, width, height}) )
    })

    // Only allow snapping if all the selected layers have the same size/position
    snapToCenter.current = uniq(sizesAndLocations).length === 1
  }, [layers, activeLayerIds])


  const [canBeTemplate, setCanBeTemplate] = useState(false)
  useEffect(() => {
    if (!image.id) return
    checkIfTemplateAllowed()

    async function checkIfTemplateAllowed () {
      const allowed = await rpc('imageTemplates.canCreate', {imageId: image.id})
      setCanBeTemplate(allowed)
    }
  }, [image.id])

  // Undo / Redo :)
  const historyStack = useRef([])
  const historyPosition = useRef(0)
  const jumpingHistory = useRef(false)
  const historyFrozen = useRef(false)

  const canUndo = historyPosition.current > 1
  const canRedo = historyPosition.current < historyStack.current.length

  const canDelete          = activeLayerIds.length > 0
  const canChangeShadow    = activeLayerIds.length > 0
  const canChangeAlignment = logoSelected || textboxesSelected
  const canChangeColor     = logoSelected || textboxesSelected
  const canRemoveColor     = logoSelected && selectedLayers.length === 1 && selectedLayers[0].color


  function deselectAllLayers () {
    if (editingText) {
      const textLayer = layers.find(l => l.id === editingText.id)
      if (textLayer) {
        changeLayerText(textLayer, textLayer.metadata.text.trim())
      }
    }
    setActiveLayerIds([])
    setEditingText(null)
  }

  function undo () {
    if (!canUndo) return

    jumpingHistory.current = true
    historyPosition.current -= 1

    const index = historyPosition.current - 1
    setActiveLayerIds(historyStack.current[index].activeLayerIds)
    setLayers(        historyStack.current[index].layers)
  }

  function redo () {
    if (!canRedo) return

    jumpingHistory.current = true
    historyPosition.current += 1

    const index = historyPosition.current - 1
    setActiveLayerIds(historyStack.current[index].activeLayerIds)
    setLayers(        historyStack.current[index].layers)
  }

  function removeSelectedLayers () {
    const layersToDelete = selectedLayers.filter(l => l.type !== 'video')
    const deadLayerIds = layersToDelete.map(l => l.id)
    const remainingLayers = layers.filter(l => !deadLayerIds.includes(l.id))
    deselectAllLayers()
    setLayers(remainingLayers)
  }

  function pauseUndoHistory () {
    // Small delay to fix issue when switching from one toolbar popup to
    // another, where the first one sends resumeUndoHistory after the second
    // one has paused it.
    setTimeout(() => {
      historyFrozen.current = true
    }, 1)
  }


  function resumeUndoHistory () {
    historyFrozen.current = false
    // Update the layers to record changes made since the history was paused
    setLayers(v => [...v])
  }


  useEffect(() => {
    if (historyFrozen.current) {
      // Do nothing
    } else if (jumpingHistory.current) {
      jumpingHistory.current = false
    } else {
      // Throw away any "future" events for redo when the user starts moving forward again
      const previousState = historyStack.current[historyPosition.current - 1]
      const currentState = {layers, activeLayerIds}
      if (JSON.stringify(currentState) !== JSON.stringify(previousState)) {
        historyStack.current = historyStack.current.slice(0, historyPosition.current)

        console.log('Saving change to undo/redo stack')
        historyStack.current.push(currentState)
        historyPosition.current += 1
      }
    }

    const unchanged = (
      historyStack.current.length <= 1 ||
      JSON.stringify(historyStack.current[0].layers) ===
      JSON.stringify(historyStack.current[historyPosition.current - 1].layers)
    )
    setHasEdits(!unchanged)

    if (!editingText) {
      document.addEventListener('keydown', onKeyDown)
      document.addEventListener('keyup', onKeyUp)
    }
    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }

    function onKeyDown (event) {
      if (event.key === 'Backspace') {
        removeSelectedLayers()
        return
      }

      ctrlKeyDown.current  = !!event.metaKey || !!event.ctrlKey
      altKeyDown.current   = !!event.altKey
      shiftKeyDown.current = !!event.shiftKey

      if (!event.metaKey && !event.ctrlKey) return

      if (event.key === 'z') {
        if (event.shiftKey) {
          redo()
        } else {
          undo()
        }
        event.preventDefault()
        return
      }
    }

    function onKeyUp (event) {
      ctrlKeyDown.current  = !!event.metaKey || !!event.ctrlKey
      altKeyDown.current   = !!event.altKey
      shiftKeyDown.current = !!event.shiftKey
    }

  }, [layers, activeLayerIds, editingText])


  function fontSize (layer) {
    let {size} = layer.metadata
    size ||= 12
    return Number(size)
  }


  function lineSpacing (layer) {
    let {spacing} = layer.metadata
    spacing ||= 0
    return Number(spacing)
  }


  function letterSpacing (layer) {
    let {kerning} = layer.metadata
    kerning ||= 0
    return Number(kerning)
  }


  function lineHeight (layer) {
    // This "magic" value works well in Chrome -- other browsers may vary.
    const defaultLineHeight = 1.15

    // Now we need to convert the line spacing into a line height multiplier
    const diff = lineSpacing(layer) / fontSize(layer)
    return defaultLineHeight + diff
  }


  // Keyboard events for grouping, ungrouping and clearing selected layers
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }

    function onKeyDown (event) {
      if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key === 'g') {
        ungroupSelectedLayers()
        event.preventDefault()
        return
      } else if ((event.metaKey || event.ctrlKey) && event.key === 'g') {
        groupSelectedLayers()
        event.preventDefault()
        return
      } else if (editingText && (event.key === 'Escape' || (event.key === 'Enter' && event.metaKey))) {
        setEditingText(null)
      } else if (((event.metaKey || event.ctrlKey) && event.key === 'd') || event.key === 'Escape') {
        deselectAllLayers()
        event.preventDefault()
      }
    }

    function groupSelectedLayers () {
      if (activeLayerIds.length <= 1) {
        alert('Select at least 2 layers to make a group')
        return
      }

      const selectedLayers = []
      const otherLayers = []

      layers.forEach(layer => {
        if (activeLayerIds.includes(layer.id)) {
          if (isGroup(layer)) {
            const subLayers = ungroupLayer(layer)
            if (!subLayers) {
              alert(`Failed to merge groups. Please ungroup first.`)
              return
            }
            selectedLayers.push(...subLayers)
          } else {
            selectedLayers.push(layer)
          }
        } else {
          otherLayers.push(layer)
        }
      })

      const newGroupLayer = groupLayers(selectedLayers)
      if (!newGroupLayer) return // In case of an alert was shown

      deselectAllLayers()
      setLayers(zValueCleanup([...otherLayers, newGroupLayer]))
      setActiveLayerIds([newGroupLayer.id])
    }


    function groupLayers(selectedLayers) {
      if (selectedLayers.find(l => l.type === 'logo')) {
        alert(`The logo layer can't be put into a group`)
        return
      }

      if (selectedLayers.find(l => l.type === 'textbox')) {
        alert(`A textbox layer can't be grouped`)
        return
      }

      if (selectedLayers.find(l => isGroup(l))) {
        alert(`You can't triple stamp a double-stamp! (No grouping groups)`)
        return
      }

      let left   = Number.POSITIVE_INFINITY
      let top    = Number.POSITIVE_INFINITY
      let right  = Number.NEGATIVE_INFINITY
      let bottom = Number.NEGATIVE_INFINITY
      let z      = Number.NEGATIVE_INFINITY

      selectedLayers.forEach((layer) => {
        left   = Math.min( left,   layer.x )
        top    = Math.min( top,    layer.y )
        right  = Math.max( right,  layer.x + layer.width )
        bottom = Math.max( bottom, layer.y + layer.height )

        // The new grouped layer will have the highest z value of all sublayers
        z = Math.max( z, layer.z )
      })

      const subLayers = selectedLayers.map(l => {
        return {
          ...l,
          x: l.x - left,
          y: l.y - top,
        }
      })

      const newLayer = {
        id:      `new-${new Date().valueOf()}`,
        type:    '',
        width:   right - left,
        height:  bottom - top,
        x:       left,
        y:       top,
        z:       z,
        brandable: !!subLayers.find(l => l.brandable),
        layers:    zValueCleanup(subLayers),
      }

      return newLayer
    }


    function ungroupSelectedLayers () {
      if (activeLayerIds.length !== 1) {
        alert('Select 1 layer that you want to ungroup')
        return
      }

      const groupedLayer = layers.find(l => l.id === activeLayerIds[0])

      const ungroupedLayers = ungroupLayer(groupedLayer)
      if (!ungroupedLayers) return // In case of an alert was shown

      // Remove the grouped layer
      let allLayers = layers.filter(l => l.id !== groupedLayer.id)

      allLayers = zValueCleanup([...allLayers, ...ungroupedLayers])

      deselectAllLayers()
      setLayers(allLayers)
      setActiveLayerIds(ungroupedLayers.map(l => l.id))
    }


    function ungroupLayer(groupLayer) {
      if (groupLayer && !isGroup(groupLayer)) {
        alert(`You can't ungroup a non-group layer`)
        return
      }

      const ungroupedLayers = sortBy(groupLayer.layers, 'z').map((l, i) => {
        return {
          ...l,
          x: l.x + groupLayer.x,
          y: l.y + groupLayer.y,

          // Keep the z values in the same order as they were when grouped, but
          // but use a small decimal value to keep them together in the z-stack
          // when they are merged back into the root layer list, and put them
          // in the z-order of the group that they were just ungrouped from.
          z: groupLayer.z + (0.001 * i)
        }
      })

      return ungroupedLayers
    }

  }, [layers, activeLayerIds])




  useEffect(() => {
    if (dragging) {
      setupDragging()
    } else {
      teardownDragging()
    }
    return teardownDragging

    function dragMove (e) {
      let diffX = (e.clientX - dragData.current.xMouse) * imageScale
      let diffY = (e.clientY - dragData.current.yMouse) * imageScale

      // Don't let any of the layers move too far off secreen
      diffX = Math.max( dragData.current.minDiffX, diffX )
      diffY = Math.max( dragData.current.minDiffY, diffY )
      diffX = Math.min( dragData.current.maxDiffX, diffX )
      diffY = Math.min( dragData.current.maxDiffY, diffY )


      setLayers(
        layers.map(layer => {
          if (!activeLayerIds.includes(layer.id)) return layer

          const {x, y} = dragData.current.layerStart[layer.id]

          let newX = x + diffX
          let newY = y + diffY

          if (snapToCenter.current && !ctrlKeyDown.current) {
            const middleY = image.height / 2 - layer.height / 2
            const centerX = image.width  / 2 - layer.width  / 2

            if (Math.abs(newX - centerX) < 30) newX = centerX
            if (Math.abs(newY - middleY) < 30) newY = middleY
          }

          return {
            ...layer,
            x: newX,
            y: newY,
          }
        })
      )
    }

    function stopDrag(e) {
      setDragging(false)
    }


    function setupDragging(e) {
      document.addEventListener('pointermove', dragMove)
      document.addEventListener('pointerup', stopDrag)

      historyFrozen.current = true

      const padding = imageScale * 18 // pixels

      let minDiffX = Number.NEGATIVE_INFINITY
      let minDiffY = Number.NEGATIVE_INFINITY
      let maxDiffX = Number.POSITIVE_INFINITY
      let maxDiffY = Number.POSITIVE_INFINITY

      activeLayerIds.forEach(id => {
        const layer = layers.find(l => l.id === id)
        if (!layer) return

        let minX = 0
        let minY = 0
        let maxX = 0
        let maxY = 0

        if (layer.type === 'logo') {
          minX = padding
          minY = padding
          maxX = image.width  - layer.width  - padding
          maxY = image.height - layer.height - padding
        } else {
          minX = -image.width  + padding
          minY = -image.height + padding
          maxX =  image.width  - padding
          maxY =  image.height - padding
        }

        minDiffX = Math.max( minDiffX, minX - layer.x )
        minDiffY = Math.max( minDiffY, minY - layer.y )
        maxDiffX = Math.min( maxDiffX, maxX - layer.x )
        maxDiffY = Math.min( maxDiffY, maxY - layer.y )
      })

      dragData.current.minDiffX = minDiffX
      dragData.current.minDiffY = minDiffY
      dragData.current.maxDiffX = maxDiffX
      dragData.current.maxDiffY = maxDiffY
    }

    function teardownDragging(e) {
      document.removeEventListener('pointermove', dragMove)
      document.removeEventListener('pointerup', stopDrag)

      historyFrozen.current = false
      setLayers(v => [...v])
    }
  }, [dragging, setDragging])


  function changeSelectedLayers(data) {
    setLayers(
      layers.map(layer => {
        if (!activeLayerIds.includes(layer.id)) return layer

        if (layer.type === 'textbox') {
          if (data.color) { setDefaultFontColor(data.color) }
        }

        return {
          ...layer,
          ...data,
        }
      })
    )
  }


  function changeSelectedLayerMetadata(data) {
    setLayers(
      layers.map(layer => {
        if (!activeLayerIds.includes(layer.id)) return layer

        if (layer.type === 'textbox') {
          if (data.font) { setDefaultFont(data.font) }
          if (data.size) { setDefaultFontSize(data.size) }
        }

        return {
          ...layer,
          metadata: {
            ...layer.metadata,
            ...data
          },
        }
      })
    )
  }

  function changeLayerMetadata(layerToChange, data) {
    setLayers(
      layers.map(layer => {
        if (layer.id !== layerToChange.id) return layer

        return {
          ...layer,
          metadata: {
            ...layer.metadata,
            ...data
          },
        }
      })
    )
  }

  function changeLayerText(layerToChange, text) {
    changeLayerMetadata(layerToChange, {text: text})
  }

  // Apply color to selected layers (but only layers that can take a color)
  function handleColorClick (color) {
    if (!color) return

    const layerIdsToChange = activeLayerIds.filter(id => {
      const layer = layers.find(l => l.id === id)
      return layer && (layer.type === 'logo' || layer.type === 'textbox')
    })

    setLayers(layers => {
      return layers.map(layer => {
        if (!layerIdsToChange.includes(layer.id)) return layer
        return {
          ...layer,
          color: color,
        }
      })
    })
  }


  const [showUpload, setShowUpload] = useState(false)

  function addImageLayer() {
    setShowUpload(true)
  }

  function handleUpload (files) {
    setShowUpload(false)

    // Put it above of the top-most image layer
    const imageLayers = layers.filter(l => l.type !== 'logo' && l.type !== 'textbox')
    const zValues = imageLayers.map(l => l.z)

    const newLayers = files.map(file => {
      let z = (max(zValues) || 0) + 0.01
      zValues.push(z)

      let height = file.metadata.dimensions.height
      let width  = file.metadata.dimensions.width

      // Scale the image to fit the preview area
      const scale = Math.min(image.width / width, image.height / height)
      if (scale < 1) {
        width *= scale
        height *= scale
      }

      const layer = {
        id:     `new-${new Date().valueOf()}_${file.id}`,
        type:   'photo',
        width:  width,
        height: height,
        x:      image.width  / 2 - width  / 2,
        y:      image.height / 2 - height / 2,
        z:      z,
        file:   file,
      }
      return layer
    })

    setLayers([...layers, ...newLayers])
    setActiveLayerIds(newLayers.map(l => l.id))
  }


  function addLogoLayer() {
    // Hard-coded sizes to match the logo area placeholder images (for now)
    const logoWidth  = 520
    const logoHeight = 150

    const logoLayer = {
      id:     `new-${new Date().valueOf()}`,
      type:   'logo',
      width:  logoWidth,
      height: logoHeight,
      x:      image.width  / 2 - logoWidth  / 2,
      y:      image.height / 2 - logoHeight / 2,
      z:      max(layers.map(l => l.z) || 0) + 1, // Put it on top
      metadata: {
        alignY: 'middle',
        alignX: 'center',
      }
    }
    setLayers([...layers, logoLayer])
    setActiveLayerIds([logoLayer.id])
  }

  function addTextBoxLayer() {
    const textboxCount = layers.filter(l => l.type === 'textbox').length
    const bodyFont  = fonts.find(f => f.name === 'OpenSans-Regular')?.uid || ''
    const titleFont = fonts.find(f => f.name === 'OpenSans-Bold'   )?.uid || ''

    let width  = image.width * 0.9
    let height = image.height * 0.275

    // Center the text
    let x = image.width  / 2 - width  / 2
    let y = image.height / 2 - height / 2
    let z = max(layers.map(l => l.z) || 0) + 1 // Put it on top

    let color    = defaultFontColor || '#FFFFFF'
    let fontSize = defaultFontSize  || 62 // Pixels
    let fontId   = defaultFont      || titleFont

    let text   = 'Add your text here'
    let alignX = 'center'
    let alignY = 'middle'

    // Set defaults for 1st two textboxes (title and body) in image templates
    if (mode === 'template') {
      if (textboxCount === 0) {
        // 1st text box (title) is bigger and moveed to the top
        fontId   = titleFont
        fontSize = 80
        y        = image.height * 0.08
        alignY   = 'bottom'
        text     = 'Here is the title area. Edit this text to show the ideal title length.'
      } else if (textboxCount === 1) {
        // 2nd text box (body)
        fontId   = bodyFont
        fontSize = 62
        height   = image.height * 0.55
        y        = image.height / 2.55
        alignY   = 'top'
        text     = 'Here is the body text. After you pick a font and adjust the size of this text area, edit this text to make it fill up the available space. This will help the app decide when this template is a good choice for a post. Avoid any newlines and special characters.'
      }
    }

    const layer = {
      id:     `new-${new Date().valueOf()}`,
      type:   'textbox',
      width:  width,
      height: height,
      x:      x,
      y:      y,
      z:      z,
      color:  color,
      metadata: {
        alignY: alignY,
        alignX: alignX,
        font:   fontId,
        size:   fontSize,
        text:   text,
        spacing: 0,
        kerning: 0,
        shadow: defaultShadow,
      }
    }
    setLayers([...layers, layer])
    setActiveLayerIds([layer.id])
    startEditingTextLayer(layer)
  }

  function handlePointerDownOnLayer (e, layer) {
    e.stopPropagation()

    clickData.current = {
      layerId: layer.id,
      x:       e.clientX,
      y:       e.clientY,
      target:  e.target,
      layerWasSelected: isSelected(layer),
    }

    if (editingText) {
      return
    }

    if (isSelectable(layer)) {
      setActiveLayerIds([layer.id])
    }
    if (isDraggable(layer)) {
      startDragging(e, layer)
    }
  }


  function handlePointerUpOnLayer (e, layer) {

    if (resizing) {
      return
    }

    e.stopPropagation()

    if (dragging) {
      setDragging(false)

      const {xMouse, yMouse} = dragData.current
      const diffX = Math.abs(e.clientX - xMouse)
      const diffY = Math.abs(e.clientY - yMouse)
      const distance = Math.sqrt(diffX * diffX + diffY * diffY)
      const dragged = (distance >= 2)

      if (dragged) return
    }

    // Ignore the up event if the down event was on a different layer
    if (clickData.current.layerId !== layer.id) {
      return
    }

    // A second tap on a textbox will switch it into editing mode
    const shouldEditText = (
      isSelected(layer) &&
      layer.type === 'textbox' &&
      clickData.current.layerWasSelected
    )
    if (shouldEditText) {
      startEditingTextLayer(layer)
      return
    }

    if (isDraggable(layer)) {
      setActiveLayerIds([layer.id])
    } else if (isEditingText(layer)) {
      // Don't lose focus when editing text
      e.preventDefault()
    }
  }


  function handleDoubleClickOnLayer (e, layer) {
    if (layer.type === 'textbox') {
      startEditingTextLayer(layer)
    } else {
      console.warn('Double click not implemented for', layer.type)
    }
  }

  function startEditingTextLayer (layer) {
    setEditingText(layer)

    setTimeout(() => {
      const textLayerId = `text-layer-editor-${layer.id}`
      const textarea = document.getElementById(textLayerId)

      if (!textarea) {
        console.error(`Could not find textarea for layer ${layer.id} with id ${textLayerId}`)
        return
      }

      textarea.focus()
      textarea.setSelectionRange(0, textarea.value.length)
    }, 0)
  }

  function setLayerType(layer, type) {
    setLayers(
      layers.map(l => l.id === layer.id ? {...l, type: type} : l)
    )
  }

  function toggleLayerBrandable(layer) {
    setLayers(
      layers.map(l => {
        if (l.id !== layer.id) return l

        // Don't allow brandable flag on logos that don't have a color
        const colorlessLogo = (l.type === 'logo' && hasNoColor(l))
        if (colorlessLogo) return l

        return {
          ...l,
          brandable: !l.brandable,
        }
      })
    )
  }


  function reverseLayers() {
    const zValues = sort(layers.map(l => l.z))
    setLayers(
      reversedLayers.map((layer, i) => {
        return {
          ...layer,
          z: layer.type === 'logo' ? zValues.pop() : zValues.shift(),
        }
      })
    )
  }


  async function createTemplate() {
    withLoading(async () => {
      await rpc('imageTemplates.copyCatalogImage', {imageId: image.id})
      setCanBeTemplate(false)
    })
  }


  function toggleShortcuts() {
    setShortcutsVisible(v => !v)
  }


  async function save() {
    withLoading(async () => {
      // Clean up the z values so they are consecutive integers (0, 1, 2, 3, ...)
      const layerData = zValueCleanup(layers)
      try {
        const newImage = await rpc('images.updateLayers', {imageId: image.id, layers: layerData, orgId: org?.id}, {throwErrors: true})
        if (onSave) onSave(newImage)
      } catch (e) {
        console.error('Error saving layers', e)
        sendMessage('error', "Couldn't save the image")
        return
      }
    })
  }

  function hasNoColor(layer) {
    // Check if color is missing or completely transparent
    return !layer.color || layer.color.match(/^#?.{6}00$/)
  }

  function isGroup(layer) {
    return layer.layers && layer.layers.length > 0
  }

  function isDraggable(layer) {
    if (layer.type === 'video') return false
    if (editingText?.id === layer.id) return false

    if (isSelected(layer)) return true

    if (activeLayerIds.length > 0) return false // Only one at a time
    if (!isSelectable(layer)) return false

    return true
  }

  function isEditingText(layer) {
    return editingText?.id === layer.id && activeLayerIds.includes(layer.id)
  }

  function isSelected(layer) {
    return activeLayerIds.includes(layer.id)
  }

  function isSelectable(layer) {
    if (editingText) return false // Don't lose focus when editing text
    if (activeLayerIds.length > 0) return false // Only one at a time

    // This is needed (for now) to deal with the way that we imported our layers
    // where they are all the full size of the image. To fix this we either need
    // to crop them, or we need a selection algorithm that can see past the
    // transparent areas of a layer to find the next layer.
    if (layer.width === image.width && layer.height === image.height) {
      return false
    }

    const backgroundLayer = sortBy(layers, 'z')[0]
    if (backgroundLayer && backgroundLayer.id === layer.id) return false

    return true
  }

  function startDragging(e, layer) {
    if (!isDraggable(layer)) return

    setDragging(true)

    dragData.current = {
      xMouse:  e.clientX,
      yMouse:  e.clientY,
      layerStart: {},
    }

    layers.forEach(layer => {
      if (!activeLayerIds.includes) return
      const {x, y} = layer
      dragData.current.layerStart[layer.id] = {x, y}
    })
  }

  const [resizing, setResizing] = useState(null)

  function startResize(e, layer, handleName) {
    e.stopPropagation()
    e.preventDefault()
    historyFrozen.current = true
    setResizing(handleName)
    resizeData.current = {
      xStart:  e.clientX,
      yStart:  e.clientY,
      layer:   layer,
      start:   {
        x: layer.x,
        y: layer.y,
        width: layer.width,
        height: layer.height,
      },
    }
  }

  useEffect(() => {
    if (!resizing) return

    function resizeMove(e) {

      let diffX = (e.clientX - resizeData.current.xStart) * imageScale
      let diffY = (e.clientY - resizeData.current.yStart) * imageScale

      setLayers(layers => {
        return layers.map(layer => {
          if (layer.id !== resizeData.current.layer.id) return layer

          // Get the starting size and position of the layer
          let {x, y, width, height} = resizeData.current.start

          const horizontalResize = resizing.includes('left') || resizing.includes('right')
          const verticalResize   = resizing.includes('top')  || resizing.includes('bottom')

          // Figure out how much the size should change
          let widthChange  = horizontalResize ? diffX * (altKeyDown.current ? 2 : 1) : 0
          let heightChange = verticalResize   ? diffY * (altKeyDown.current ? 2 : 1) : 0

          if (resizing.includes('top'))  heightChange *= -1
          if (resizing.includes('left')) widthChange  *= -1

          let newHeight = height + heightChange
          let newWidth  = width  + widthChange

          // Don't let the layer get too small
          let [minWidth, minHeight] = [40, 40]
          if (layer.type === 'logo') {
            [minWidth, minHeight] = [520, 150]
          }
          if (layer.type === 'textbox') {
            [minWidth, minHeight] = [120, 60]
          }

          if (newWidth  <  minWidth) newWidth  = minWidth
          if (newHeight < minHeight) newHeight = minHeight

          // Maintain the aspect ratio for things like photos (or holding Shift)
          const maintainRatio = maintainAspectRatio || shiftKeyDown.current
          if (maintainRatio && height) {
            const xScale = newWidth  / width
            const yScale = newHeight / height

            let scale = 1
            if (horizontalResize && verticalResize) scale = Math.min(xScale, yScale)
            else if (horizontalResize)              scale = xScale
            else if (verticalResize)                scale = yScale
            else                                    scale = 1

            const minScale = Math.max(minWidth / width, minHeight / height)
            if (scale < minScale) scale = minScale

            newWidth  = width  * scale
            newHeight = height * scale
          }

          // Figure out how to move the layer based on resize type and direction
          let xFactor = 0
          if (resizing.includes('left')) xFactor = -1
          if (horizontalResize && altKeyDown.current ) xFactor = -0.5
          if (maintainRatio && verticalResize && !horizontalResize) xFactor = -0.5
          x += xFactor * (newWidth - width)

          let yFactor = 0
          if (resizing.includes('top')) yFactor = -1
          if (verticalResize && altKeyDown.current) yFactor = -0.5
          if (maintainRatio && horizontalResize && !verticalResize) yFactor = -0.5
          y += yFactor * (newHeight - height)

          // Update the layer with the new size
          width  = newWidth
          height = newHeight

          return { ...layer, x, y, width, height }
        })
      })
    }

    function stopResize(e) {
      setResizing(null)
      historyFrozen.current = false

      // Set the layers one last time to put an entry in the history stack
      setLayers(all => [...all])

      document.removeEventListener('pointermove', resizeMove)
      document.removeEventListener('pointerup', stopResize)
    }

    document.addEventListener('pointermove', resizeMove)
    document.addEventListener('pointerup', stopResize)
  }, [resizing])

  function revert() {
    if (onCancel) onCancel()
  }


  function filterForLayer(layer, scale) {
    if (layer.metadata?.shadow) {
      return `drop-shadow(${shadowStyle(layer, scale)})`
    } else {
      return ''
    }
  }

  function shadowStyle(layer, scale) {
    if (!layer.metadata?.shadow) return ''

    let {blur, color, opacity, x, y} = layer.metadata.shadow
    x = x / scale
    y = y / scale
    blur = blur / scale // Fudge factor to match imagemagick output

    const shadowColor = hexToRgba(color, opacity)
    return `${x}px ${y}px ${blur}px ${shadowColor}`
  }

  function flexAlign(layer) {
    if (layer.metadata.alignY === 'top')    return 'flex-start'
    if (layer.metadata.alignY === 'middle') return 'center'
    if (layer.metadata.alignY === 'bottom') return 'flex-end'
  }

  function flexJustify(layer) {
    if (layer.metadata.alignX === 'left')   return 'flex-start'
    if (layer.metadata.alignX === 'center') return 'center'
    if (layer.metadata.alignX === 'right')  return 'flex-end'
  }

  const imagePreviewStyle = {
    height: image.height / imageScale,
    width:  image.width  / imageScale,
    touchAction: 'none',
  }

  let showCenterLine = false
  let showMiddleLine = false

  activeLayerIds.forEach(id => {
    const layer = layers.find(l => l.id === id)
    if (!layer) return

    showCenterLine = showCenterLine || (
      layer && layer.x === (image.width / imageScale - layer.width / imageScale)
    )
    showMiddleLine = showMiddleLine || (
      layer && layer.y === (image.height / imageScale - layer.height / imageScale)
    )
  })

  if (!dragging || !snapToCenter.current) {
    showCenterLine = false
    showMiddleLine = false
  }

  const layerInDrag = layerDragData.current.layer

  function closeMenuAnd(fn) {
    return function () {
      setContentMenuPosition(null)
      fn()
    }
  }

  const contextMenu = (
    <div className='flex-col'>
      <button className='popup-item' onClick={closeMenuAnd(addTextBoxLayer)} disabled={!canAddTextBoxLayer}>
        Add Text
      </button>
      <button className='popup-item' onClick={closeMenuAnd(addImageLayer)} disabled={!canAddImageLayer}>
        Add Image
      </button>
      <button className='popup-item' onClick={closeMenuAnd(addLogoLayer)} disabled={!canAddLogoLayer}>
        Add Logo
      </button>
    </div>
  )

  const [contentMenuPosition, setContentMenuPosition] = useState(null)

  function handleContextMenu (e) {
    e.preventDefault()
    setContentMenuPosition({x: e.clientX, y: e.clientY})
  }

  function handlePreviewClick (e) {
    if (resizing) {
      return
    }

    if (contentMenuPosition) {
      setContentMenuPosition(null)
      return
    }

    if (e.target === e.currentTarget) {
      deselectAllLayers()
    }

  }

  const [colorResetPosition, setColorResetPosition] = useState(null)

  function handleFocusColor (e) {
    // Show the helper about logo colors (with link to erase the color)
    const rect = e.target.getBoundingClientRect()
    setColorResetPosition({x: rect.x, y: rect.y - 40})
  }

  function handleFocusBlur (e) {
    e.target.style.transform = ''
    e.target.style.opacity = 1

    // Gross hack to keep message open in case user clicks "Remove Color" link
    setTimeout(() => {
      setColorResetPosition(null)
    }, 500)
  }

  function handleRemoveLogoColor () {
    setLayers(
      layers.map(l => {
        if (l.type !== 'logo') return l
        return {...l, color: '', brandable: false}
      })
    )
  }


  return <>

  <FileUploader onUpload={handleUpload} multiple>
    <div onClick={e => {
      if (e.target === e.currentTarget) {
        deselectAllLayers()
      }
    }}
    className={classNames("image-panel", {
      'drag-in-progress': dragging,
      'preview-branding': previewBranding
    })}>
        <div className='flex-col gap-3 pb-2'>
          <div id="toolbar" style={{height: 35}}>
            <div className='flex-row bg-white rounded gap-3'>

              <div className='flex-row z-1'>
                <div className='relative'>
                  <button className='toolbar-button tiny button px-2 py-4' disabled={!canUndo} onClick={undo}>
                    <ArrowUturnLeftIcon/>
                  </button>
                  <div className='hover-parent z-3 pointer-events-none absolute mt-1'>
                    <div className='py-1 px-3 text-sm bold white bg-black-transparent rounded nowrap'>
                      Undo
                    </div>
                  </div>
                </div>

                <div className='relative'>
                  <button className='toolbar-button tiny button px-2 py-4' disabled={!canRedo} onClick={redo}>
                    <ArrowUturnRightIcon/>
                  </button>
                  <div className='hover-parent z-3 pointer-events-none absolute mt-1'>
                    <div className='py-1 px-3 text-sm bold white bg-black-transparent rounded nowrap'>
                      Redo
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex-row z-1'>
                <div className='relative'>
                  <PopupMenu className="toolbar-button" icon={<div className='text-sm pt-2 p-1'><PlusIcon/></div>} small>
                    <div className='flex-col'>
                      <button className='popup-item' onClick={addTextBoxLayer} disabled={!canAddTextBoxLayer}>
                        Add Text
                      </button>
                      <button className='popup-item' onClick={addImageLayer} disabled={!canAddImageLayer}>
                        Add Image
                      </button>
                      <button className='popup-item' onClick={addLogoLayer} disabled={!canAddLogoLayer}>
                        Add Logo
                      </button>
                    </div>
                  </PopupMenu>
                  <div className='hover-parent z-3 pointer-events-none absolute mt-1'>
                    <div className='py-1 px-3 text-sm bold white bg-black-transparent rounded nowrap'>
                      Add
                    </div>
                  </div>
                </div>
                <div className={classNames('relative', {hidden: !canDelete})}>
                  <button className='toolbar-button text-sm button px-2 py-1' disabled={!canDelete} onClick={removeSelectedLayers}>
                    <TrashIcon/>
                  </button>
                  <div className='hover-parent z-3 pointer-events-none absolute mt-1'>
                    <div className='py-1 px-3 text-sm bold white bg-black-transparent rounded nowrap'>
                      Delete
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex-row z-1'>
                <div className={classNames('flex-row z-1', {hidden: !canChangeShadow})}>
                  <ShadowPicker
                    shadow={selectedLayersShadow}
                    colorEditable={canEditShadowColor}
                    onChange={shadow => changeSelectedLayerMetadata({shadow})}
                    onOpen={pauseUndoHistory}
                    onClose={resumeUndoHistory}
                  />

                </div>


                <div className='flex-row z-1'>
                  <div className={classNames('z-5 relative', {hidden: !canChangeColor})}>
                    <PopupMenu
                      className="toolbar-button"
                      small
                      icon={<div className='border border-darker-gray' style={{background: selectedLayersColor || '#FFFFFF', width: '1.5rem', height: '1.5rem', borderRadius: '50%'}}></div>}
                      disabled={!canChangeColor}
                      onOpen={pauseUndoHistory}
                      onClose={resumeUndoHistory}
                    >
                      <div className='p-3 flex-col gap-4'>

                        <div className='flex-row gap-2'>
                          <input
                            className='toolbar-button button'
                            style={{width: '2rem', padding: '2px 3px'}}
                            type="color"
                            value={selectedLayersColor || '#ffffff'}
                            onChange={e => changeSelectedLayers({color: e.target.value})}
                          />
                          &larr; Pick a custom color
                        </div>
                        { recommendedColors.length > 0 &&
                          <div className='flex-col gap-2'>
                            <div className='dark-gray flex-row gap-2'>
                              Or use one of these...
                            </div>
                            <div className='grid grid-cols-6 gap-1'>
                              { recommendedColors.map(color => {
                                return (
                                  <div
                                    key={color}
                                    className='flex-row gap-1'
                                  >
                                    <button
                                      className='button border border-gray shadow rounded'
                                      style={{width: '2.2rem', height: '2.2rem', backgroundColor: color}}
                                      onClick={e => changeSelectedLayers({color: color})}
                                    >
                                    </button>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        }

                        { canRemoveColor && (
                          <div className='mt-2 flex-col gap-2'>
                            <div className='dark-gray'>
                              Or restore the original color...
                            </div>
                            <button
                              className='button hover_red flex-row gap-2'
                              onClick={e => changeSelectedLayers({color: ''})}
                            >
                              <TrashIcon/>
                              Remove Color
                            </button>
                          </div>
                        )}

                      </div>
                    </PopupMenu>
                    <div className='hover-parent z-3 pointer-events-none absolute mt-1'>
                      <div className='py-1 px-3 text-sm bold white bg-black-transparent rounded nowrap'>
                        Color
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classNames('flex-row z-1', {hidden: !canChangeAlignment})}>
                  <div className='z-5 relative'>
                    <AlignmentControl
                    alignX={selectedLayersAlignment.x}
                    alignY={selectedLayersAlignment.y}
                    onChange={({alignX, alignY}) => changeSelectedLayerMetadata({alignX, alignY})}
                    disabled={!canChangeAlignment}
                    onOpen={pauseUndoHistory}
                    onClose={resumeUndoHistory}
                  />
                  </div>
                </div>

                <div className={classNames('flex-row z-1', {hidden: !textboxesSelected})}>
                  <FontPicker
                    fonts={fonts}
                    uid={selectedLayersFontId}
                    size={selectedLayersFontSize}
                    onChangeFont={newFont => changeSelectedLayerMetadata({font: newFont})}
                    onChangeSize={newSize => changeSelectedLayerMetadata({size: newSize})}
                    onOpen={pauseUndoHistory}
                    onClose={resumeUndoHistory}
                  />
                </div>
              </div>

            </div>


          </div>

          <div className="image-preview" style={imagePreviewStyle} onContextMenu={handleContextMenu} onPointerUp={handlePreviewClick}>
            {showCenterLine && <div className='center-line'/>}
            {showMiddleLine && <div className='middle-line'/>}
            {sortedLayers.map(layer => (
              <div
                className={
                  classNames("layer", {
                    inactive:   activeLayerIds.length && !activeLayerIds.includes(layer.id),
                    active:     activeLayerIds.includes(layer.id),
                    draggable:  isDraggable(layer),
                    dragging:   dragging && activeLayerIds.includes(layer.id),
                    brandable:  layer.brandable,
                    selectable: isSelectable(layer),
                    selected:   isSelected(layer),
                  })
                }
                key={layer.id}
                style={{
                  height: layer.height / imageScale,
                  width:  layer.width  / imageScale,
                  left:   layer.x      / imageScale,
                  top:    layer.y      / imageScale,
                }}
                onPointerDown={e => handlePointerDownOnLayer(e, layer)}
                onPointerUp={e => handlePointerUpOnLayer(e, layer)}
                onDoubleClick={e => handleDoubleClickOnLayer(e, layer)}
              >
                {(() => {
                  const handleDiameter = 14
                  const handleOffset = handleDiameter / 2 + 1

                  if (!isSelected(layer)) return null
                  if (activeLayerIds.length !== 1) return null
                  if (layer.type === 'video') return null

                  return (
                    <div className='absolute-full pointer-events-none z-2'>
                      <div onPointerDown={e => startResize(e, layer, 'top')}           className={classNames('pointer-events-all cursor-ns-resize   z-1 bg-white hover_bg-mild-blue border border-333 absolute opacity-90 rounded-50 shadow-lg', {invisible: (resizing && resizing !== 'top'         ), 'bg-mild-blue': resizing === 'top'         })} style={{width: handleDiameter, height: handleDiameter, top:    -handleOffset, left: '50%', transform: 'translateX(-50%)'}}/>
                      <div onPointerDown={e => startResize(e, layer, 'bottom')}        className={classNames('pointer-events-all cursor-ns-resize   z-1 bg-white hover_bg-mild-blue border border-333 absolute opacity-90 rounded-50 shadow-lg', {invisible: (resizing && resizing !== 'bottom'      ), 'bg-mild-blue': resizing === 'bottom'      })} style={{width: handleDiameter, height: handleDiameter, bottom: -handleOffset, left: '50%', transform: 'translateX(-50%)'}}/>
                      <div onPointerDown={e => startResize(e, layer, 'left')}          className={classNames('pointer-events-all cursor-ew-resize   z-1 bg-white hover_bg-mild-blue border border-333 absolute opacity-90 rounded-50 shadow-lg', {invisible: (resizing && resizing !== 'left'        ), 'bg-mild-blue': resizing === 'left'        })} style={{width: handleDiameter, height: handleDiameter, left:   -handleOffset, top:  '50%', transform: 'translateY(-50%)'}}/>
                      <div onPointerDown={e => startResize(e, layer, 'right')}         className={classNames('pointer-events-all cursor-ew-resize   z-1 bg-white hover_bg-mild-blue border border-333 absolute opacity-90 rounded-50 shadow-lg', {invisible: (resizing && resizing !== 'right'       ), 'bg-mild-blue': resizing === 'right'       })} style={{width: handleDiameter, height: handleDiameter, right:  -handleOffset, top:  '50%', transform: 'translateY(-50%)'}}/>

                      <div onPointerDown={e => startResize(e, layer, 'top-left')}      className={classNames('pointer-events-all cursor-nwse-resize z-1 bg-white hover_bg-mild-blue border border-333 absolute opacity-90 rounded-50 shadow-lg', {invisible: (resizing && resizing !== 'top-left'    ), 'bg-mild-blue': resizing === 'top-left'    })} style={{width: handleDiameter, height: handleDiameter, top:    -handleOffset, left:  -handleOffset}}/>
                      <div onPointerDown={e => startResize(e, layer, 'top-right')}     className={classNames('pointer-events-all cursor-nesw-resize z-1 bg-white hover_bg-mild-blue border border-333 absolute opacity-90 rounded-50 shadow-lg', {invisible: (resizing && resizing !== 'top-right'   ), 'bg-mild-blue': resizing === 'top-right'   })} style={{width: handleDiameter, height: handleDiameter, top:    -handleOffset, right: -handleOffset}}/>
                      <div onPointerDown={e => startResize(e, layer, 'bottom-left')}   className={classNames('pointer-events-all cursor-nesw-resize z-1 bg-white hover_bg-mild-blue border border-333 absolute opacity-90 rounded-50 shadow-lg', {invisible: (resizing && resizing !== 'bottom-left' ), 'bg-mild-blue': resizing === 'bottom-left' })} style={{width: handleDiameter, height: handleDiameter, bottom: -handleOffset, left:  -handleOffset}}/>
                      <div onPointerDown={e => startResize(e, layer, 'bottom-right')}  className={classNames('pointer-events-all cursor-nwse-resize z-1 bg-white hover_bg-mild-blue border border-333 absolute opacity-90 rounded-50 shadow-lg', {invisible: (resizing && resizing !== 'bottom-right'), 'bg-mild-blue': resizing === 'bottom-right'})} style={{width: handleDiameter, height: handleDiameter, bottom: -handleOffset, right: -handleOffset}}/>
                    </div>
                  )
                })()}

                {layer.type === 'logo' ?
                  <div
                    className='rounded layer-contents logo-preview'
                    draggable="false"
                    style={{
                      userSelect: 'none',
                      fontWeight: 900,
                      fontSize: '2rem',
                      lineHeight: '1.5rem',
                      color: layer.color ?? '#333',
                      background: (layer.color ? '' : '#EEEEEEF3'),
                      outline: (layer.color ? '' : '1px solid #AAAAAA'),
                      display: 'flex',
                      alignItems: flexAlign(layer),
                      justifyContent: flexJustify(layer),
                      overflow: 'hidden',
                    }}
                  >
                    <div className='flex flex-center gap-2' style={{transform: `scale(${1/imageScale * 2})`}}>
                      <span style={{transform: 'scale(1.3)'}}><CubeIcon solid/></span>
                      LOGO
                      <span style={{transform: 'scale(1.3)'}}><CubeIcon solid/></span>
                    </div>
                  </div>
                : layer.type === 'textbox' ?
                  <div
                    className='rounded layer-contents textbox-preview'
                    draggable="false"
                    style={{
                      userSelect: 'none',
                      display: 'flex',
                      alignItems: flexAlign(layer),
                      justifyContent: flexJustify(layer),
                    }}
                  >
                    <div
                      className='absolute-full flex flex-row'
                      style={{
                        position: 'absolute',
                        lineHeight: 'initial',
                        color: layer.color ?? '#111111B3',
                      }}
                    >
                      <div
                        className={classNames('absolute-full flex', {'overflow-hidden' : !isEditingText(layer)})}
                        style={{
                          alignItems: flexAlign(layer),
                          justifyContent: flexJustify(layer),
                        }}
                      >
                        {/*-------------------------------------------------------
                          NOTE: Chrome allows bolding of fonts that do not have a
                          bold variant. This "faux bold" is basically a thicker
                          text-stroke, but the thinkness of the stroke varies
                          based on the font size. Also, on smaller fonts, the
                          "faux bold" effect won't kick in until the font-weight
                          is higher (e.g. 900/bolder). It will take some
                          experimentation to figure out how to translate the
                          "faux bold" look into stroke settings for imagemagick.
                        -------------------------------------------------------*/}
                        <div
                          className="relative full-width"
                          style={{
                            fontStyle:      layer.metadata.text ? 'normal' : 'italic',
                            fontSize:       fontSize(layer) / imageScale,
                            fontWeight:     layer.metadata.weight,
                            fontFamily:     layer.metadata.font + ',Arial',
                            filter:         filterForLayer(layer, imageScale),
                            textAlign:      layer.metadata.alignX,
                            lineHeight:     lineHeight(layer),
                            letterSpacing:  letterSpacing(layer),
                            color:          layer.metadata.text ? layer.color ?? '#111111B3' : '#666666B3',
                            wordWrap:       'break-word',
                            whiteSpace:     'pre-wrap',
                            border:         'none',

                          }} >
                          <textarea
                            id={"text-layer-editor-" + layer.id}
                            style={{
                              outline:        'none',
                              fontSize:       fontSize(layer) / imageScale,
                              fontWeight:     layer.metadata.weight,
                              fontFamily:     layer.metadata.font + ',Arial',
                              textAlign:      layer.metadata.alignX,
                              lineHeight:     lineHeight(layer),
                              letterSpacing:  letterSpacing(layer),
                              resize:         'none',
                              color:          layer.color ?? '#111111B3',
                              wordWrap:       'break-word',
                              whiteSpace:     'pre-wrap',
                              borderWidth:    0,
                              overflowY:      'hidden',
                            }}
                            value={layer.metadata.text}
                            onChange={e => {changeLayerText(layer, e.target.value)}}
                            className={classNames(
                              'absolute-full bg-none text-center full-width',
                              {'invisible pointer-events-none': !isEditingText(layer)}
                            )}
                          />
                          <span className={classNames({invisible: isEditingText(layer)})}>
                            {layer.metadata.text || 'Double click to add text'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                :
                  <div
                    className='layer-contents'
                    draggable="false"
                    style={{ filter: filterForLayer(layer, imageScale) }}
                  >
                    { (isGroup(layer) ? sortBy(layer.layers, 'z') : [layer]).map(l => {
                      if (l.type === 'video') {
                        return <video
                          key={l.id}
                          src={l.file.url}
                          controls
                          draggable="false"
                          style={{
                            height: l.height / imageScale,
                            width:  l.width  / imageScale,
                            left:   isGroup(layer) ? l.x / imageScale : 0,
                            top:    isGroup(layer) ? l.y / imageScale : 0,
                            position: 'absolute',
                          }}
                        />
                      } else {
                        return <img
                          key={l.id}
                          src={l.file.url}
                          draggable="false"
                          className="prevent-long-press"
                          style={{
                            height: l.height / imageScale,
                            width:  l.width  / imageScale,
                            left:   isGroup(layer) ? l.x / imageScale : 0,
                            top:    isGroup(layer) ? l.y / imageScale : 0,
                            position: 'absolute',
                            filter: isGroup(layer) ? filterForLayer(l, imageScale) : '',
                          }}
                        />
                      }

                    })}
                  </div>
                }
              </div>
            ))}
          </div>

          <div className='flex flex-row spread gap-2 flex-align-start relative'>
            <div className='absolute-full' onClick={() => deselectAllLayers()}></div>

            { hasEdits ?
              <button className='button is-primary' onClick={save} disabled={!canSave}>
                Save and Close
                { !canSave &&
                  <div className='hover-parent z-3 pointer-events-none absolute left opacity-100' style={{top: '1.5rem', left: '1.5rem'}}>
                    <div className='py-1 px-3 text-sm bold white bg-dark-red rounded mt-5'>
                      Cannot save an image<br/>with empty TextBox layers
                    </div>
                  </div>
                }
              </button>
            :
              <button  onClick={revert} className='button is-primary'>
                Close
              </button>
            }

            { hasEdits &&
              <button  onClick={revert} className='button is-danger'>
                Revert
              </button>
            }
          </div>

        </div>

        <div className={classNames('layer-panel flex-col gap-3 mt-2', {hidden: layersHidden})}>
          <div className='flex-row spread'>
            <h2 className='text-lg bold'>
              Layers
            </h2>
            <PopupMenu small inverted>

              <div id='canvas-tools' className='flex-col gap-3 px-3 py-2 text-base'>
                <div className='gray text-lg text-center'>
                  Editing Image #{image.id}
                </div>

                <hr className='m-0 mb-1 mx-3' />

                <div className='flex-row z-3 gap-2'>
                  <div className='flex-row spread'>
                    <a className='z-1 center dark-gray'>
                      <label className="inline-block text-right" style={{minWidth: 150}}>
                        Canvas Brightness
                      </label>
                      <div className='hover-parent pointer-events-none absolute mt-3 px-4 bold white bg-black-transparent rounded' style={{width: 300}}>
                        <p className='my-4 text-left'>This changes the canvas background to to make it easier to see gray layers.</p>
                      </div>
                    </a>
                  </div>
                  <input
                    className=''
                    type="range"
                    value={canvasBrightness}
                    min="0"
                    max="1"
                    step="0.001"
                    onChange={e => setCanvasBrightness(e.target.value)}
                  />
                </div>

                <div className='flex-row z-2 gap-2'>
                  <a className='z-1 center dark-gray'>
                    <label className='inline-block text-right' style={{minWidth: 150}}>
                      Unselected Opacity
                    </label>
                    <div className='hover-parent pointer-events-none absolute mt-3 px-4 bold white bg-black-transparent rounded' style={{width: 300}}>
                      <p className='my-4 text-left'>When you have a layer selected, this lets you change the opacity of the other layers, which is useful for seeing  respoitioning.</p>
                    </div>
                  </a>
                  <input
                    className=''
                    type="range"
                    value={inactiveLayerOpacity}
                    min="0"
                    max="1"
                    step="0.01"
                    onChange={e => setInactiveOpacity(e.target.value)}
                  />
                </div>

                <div className='relative z-1 flex-row gap-2'>
                  <div className='text-right' style={{minWidth: 150}}>
                    Preview Branding
                    <div className='hover-parent pointer-events-none absolute mt-5 px-4 bold white bg-black-transparent rounded' style={{width: 300, top: '1rem'}}>
                      <p className='my-4 text-left'>Check this box to get a (rough) idea of what might happen when this image gets branded.</p>
                    </div>
                  </div>
                  <Checkbox checked={previewBranding} onChange={() => setPreviewBranding(v => !v)} >
                  </Checkbox>
                </div>

                <hr className='my-1 mx-3' />

                <div className='flex-row gap-2 max-width-300'>
                  <p className='text-left gray text-sm'>
                    The settings above will NOT have any effect on the final image. They only change the way things look <em>here</em>, in the image editor.
                  </p>
                </div>

                <hr className='my-1 mx-3' />

                <button className='button is-inverted' onClick={toggleShortcuts}>
                  Show Keyboard Shortcuts
                </button>

                <button className='button is-inverted' onClick={reverseLayers} disabled={!canReverse}>
                  Reverse Layer Order
                </button>

                { canBeTemplate && <>
                  <button className='button is-inverted' onClick={closeMenuAnd(createTemplate)} disabled={hasEdits}>
                    Copy to New Image Template
                  </button>
                </>}


              </div>

            </PopupMenu>
          </div>

          <div className={classNames("layers-list", {'layer-drag-in-progress': !!layerInDrag})} style={{height: image.height / imageScale}}>

            {reversedLayers.map(layer => {
              const active = activeLayerIds.includes(layer.id)
              const inactive = activeLayerIds.length && !active

              function setType(e) {
                setLayerType(layer, e.currentTarget.value)
              }

              function toggleBrandable(e) {
                toggleLayerBrandable(layer)
              }

              function toggle (e) {
                const maintainSelection = (e.controlKey || e.metaKey || e.shiftKey)

                let selectedIds = maintainSelection ? activeLayerIds : []

                if (e.shiftKey && selectedIds.length> 0 ) {
                  const zRange = sort([ layer.z, layers.find(l => l.id === last(activeLayerIds)).z ])
                  selectedIds = [
                    ...selectedIds,
                    ...layers.filter(l => (
                      !selectedIds.includes(l.id) &&
                      l.z >= first(zRange) &&
                      l.z <= last(zRange)
                    )).map(l => l.id),
                  ]
                } else if (activeLayerIds.length === 1 && activeLayerIds[0] === layer.id) {
                  selectedIds = []
                } else if (selectedIds.includes(layer.id)) {
                  selectedIds = selectedIds.filter(id => id !== layer.id)
                } else {
                  selectedIds = [...selectedIds, layer.id]
                }

                setActiveLayerIds(selectedIds)
              }

              function dragStart(e) {
                if (!isDraggable(layer)) return

                e.dataTransfer.effectAllowed = 'move'
                historyFrozen.current = true
                layerDragData.current.layer = layer
                setLayerDragZ(layer.z)
              }

              function dragEnter(e) {
                setLayerDragZ(layer.z)
              }

              function dragEnd(e) {
                const newZ = layerDragZ + (layerDragZ > layer.z ? 0.001 : -0.001)
                setLayerDragZ(-1)

                layerDragData.current = {}
                historyFrozen.current = false

                // Apply the new (non-integer) Z value to the layer that was moved
                const newLayers = layers.map(l => l.id === layer.id ? {...l, z: newZ} : l)

                // Reset the z values on the layers to use integers (while maintaining order)
                setLayers( zValueCleanup(newLayers) )
              }

              const dragging  = layerInDrag && layerInDrag.id === layer.id
              const slideUp   = layerInDrag && !dragging && layerDragZ >= 0 && layerDragZ <= layer.z && layer.z < layerInDrag.z
              const slideDown = layerInDrag && !dragging && layerDragZ >= 0 && layerDragZ >= layer.z && layer.z > layerInDrag.z

              const classes = classNames('layer-widget', {active, inactive, brandable: layer.brandable, dragging, 'slide-up': slideUp, 'slide-down': slideDown})

              return (
                <div key={layer.id} className={classes} draggable="true" onDragStart={dragStart} onDragEnter={dragEnter} onDragEnd={dragEnd}>
                  <div className="layer-thumb">
                    <div className='img-wrapper' onClick={toggle}
                      style={{
                        width:  image.width  / layerScale,
                        height: image.height / layerScale,
                      }}
                    >
                      { layer.type === 'logo' ?
                        <div
                          className="logo-area"
                          style={{
                            fontSize: '0.8rem',
                            lineHeight: '0.9rem',
                            textAlign: 'center',
                            fontWeight: 900,
                            width:  layer.width  / layerScale,
                            height: layer.height / layerScale,
                            left:   layer.x      / layerScale,
                            top:    layer.y      / layerScale,
                            color:  layer.color ?? '#111111B3',
                            background: layer.color ? 'none' : '#EEEEEE',
                            display: 'flex',
                            alignItems: flexAlign(layer),
                            justifyContent: flexJustify(layer),
                            overflow: 'hidden',
                          }
                        }>
                          LOGO
                        </div>
                      : layer.type === 'textbox' ?
                        <div
                          className="textbox-area absolute flex textbox-preview"
                          style={{
                            fontSize: layer.metadata.size / layerScale,
                            textAlign: layer.metadata.alignX,
                            fontWeight: layer.metadata.weight,
                            fontFamily: layer.metadata.font + ',Arial',
                            width:      layer.width / layerScale,
                            height:     layer.height / layerScale,
                            transformOrigin: 'top left',
                            left:   layer.x / layerScale,
                            top:    layer.y / layerScale,
                            color: layer.color || '#111111B3',
                            background: layer.color ? 'none' : '#EEEEEEB3',
                            display: 'flex',
                            alignItems: flexAlign(layer),
                            justifyContent: flexJustify(layer),
                            textShadow: shadowStyle(layer, layerScale),
                          }
                        }>
                          <div className='' style={{lineHeight: 'initial'}}>
                            {layer.metadata.text || 'Text Box'}
                          </div>
                        </div>
                      :
                        <div
                          className='thumbnail-image'
                          style={{
                            width:  layer.width  / layerScale,
                            height: layer.height / layerScale,
                            left:   layer.x      / layerScale,
                            top:    layer.y      / layerScale,
                            filter: filterForLayer(layer, layerScale),
                          }}
                        >
                          { (isGroup(layer) ? sortBy(layer.layers, 'z') : [layer]).map(l => {
                            return <img
                              key = {l.id}
                              src={l.type === 'video' ? image.thumbnailUrl : l.file.url}
                              alt=""
                              draggable="false"
                              style={{
                                width:  l.width  / layerScale,
                                height: l.height / layerScale,
                                left:   isGroup(layer) ? l.x / layerScale : 0,
                                top:    isGroup(layer) ? l.y / layerScale : 0,
                                position: 'absolute',
                                filter: filterForLayer(l, layerScale),
                              }}
                            />
                          }) }
                        </div>
                      }
                    </div>
                  </div>
                  <div className="layer-controls p-2 flex-col flex-align-start gap-2 text-sm bg-f8f8f8 rounded full-width">
                    { layer.type === 'logo' ?
                      <>
                        <div className='flex flex-row spread full-width'>
                          <div className='flex-row gap-1 gray'>
                            <CubeIcon/>Logo
                          </div>
                          <div className='flex flex-row gap-1'>
                            <div className='relative'>
                              <button className={classNames('button tiny icon-button bg-f8f8f8', {'cursor-not-allowed': !canClickColorToCopy})} onClick={() => handleColorClick(layer.color)} onFocus={handleFocusColor} onBlur={handleFocusBlur}>
                                <div
                                  className="layer-color m-1px flex flex-center relative"
                                  style={{backgroundColor: layer.color}}
                                >
                                  {hasNoColor(layer) && <div className='red text-center absolute-full opacity-50 bold' style={{fontSize: '1rem', lineHeight: '1.15rem'}}>X</div>}
                                </div>
                              </button>
                              <div className='hover_parent_opacity pointer-events-none absolute right bold white bg-black-transparent rounded mt-1 px-2 p-1 nowrap text-md'>
                                { hasNoColor(layer) ?
                                  <span>No Color (yet)</span>
                                :
                                  <div> {layer.color?.toUpperCase()}
                                    { canClickColorToCopy ?
                                      <div className='not-bold'>
                                        Click to apply this color<br/>to the selected {[
                                          logoSelected && 'logo',
                                          textboxesSelected && 'textbox',
                                        ].filter(Boolean).join(' and ')}.
                                      </div>
                                    :
                                      null
                                    }
                                  </div>
                                }
                              </div>
                            </div>
                            <div className='relative'>
                              <button className={classNames('button icon-button', {'border-blue bg-light-blue': layer.brandable})} onClick={() => toggleBrandable(layer)} disabled={!layer.color}> <SwatchIcon/> </button>
                              <div className='hover_parent_opacity pointer-events-none absolute right bold white bg-black-transparent rounded mt-1 px-2 p-1 nowrap'> Brandable: {layer.brandable ? 'ON' : 'OFF'}
                                {!layer.color && <div className='not-bold'>You must pick a color for the logo<br/> if you want to make it brandable.</div>}
                                {layer.brandable && <div className='not-bold'>The logo color will adjust<br/>to fit the user's brand.</div>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='full-width flex-row gap-1'>
                        </div>
                      </>
                    : layer.type === 'textbox' ?
                      <>
                        <div className='flex flex-row spread full-width'>
                          <div className='flex-row gap-1 gray'>
                            <span className='gray bold border border border-dark-gray border-width-2 rounded' style={{fontFamily: 'serif', padding: '0px 4px', fontSize: '1.1rem'}}>T</span>
                            TextBox
                          </div>
                          <div className='flex flex-row gap-1'>
                            <div className='relative'>
                              <button className={classNames('button tiny icon-button bg-f8f8f8', {'cursor-not-allowed': !canClickColorToCopy})} onClick={() => handleColorClick(layer.color)}>
                                <div
                                  className="layer-color m-1px flex flex-center relative"
                                  style={{backgroundColor: layer.color}}
                                >
                                  {hasNoColor(layer) && <div className='red text-center absolute-full opacity-50 bold' style={{fontSize: '1rem', lineHeight: '1.15rem'}}>X</div>}
                                </div>
                              </button>
                              <div className='hover_parent_opacity pointer-events-none absolute right bold white bg-black-transparent rounded mt-1 px-2 p-1 nowrap text-md'>
                                { hasNoColor(layer) ?
                                  <span>No Color (yet)</span>
                                :
                                  <div> {layer.color?.toUpperCase()}
                                    { canClickColorToCopy ?
                                      <div className='not-bold'>
                                        Click to apply this color<br/>to the selected {[
                                          logoSelected && 'logo',
                                          textboxesSelected && 'textbox',
                                        ].filter(Boolean).join(' and ')}.
                                      </div>
                                    :
                                      null
                                    }
                                  </div>
                                }
                              </div>
                            </div>
                            <div className='relative'>
                              <button className={classNames('button icon-button', {'border-blue bg-light-blue': layer.brandable})} onClick={() => toggleBrandable(layer)}> <SwatchIcon/> </button>
                              <div className='hover_parent_opacity pointer-events-none absolute right bold white bg-black-transparent rounded mt-1 px-2 p-1 nowrap'> Brandable: {layer.brandable ? 'ON' : 'OFF'} {layer.brandable && <div className='not-bold'>The text color will adjust<br/>to fit the user's brand.</div>} </div>
                            </div>
                          </div>
                        </div>
                        <div className='flex-row align-center gap-2 full-width hidden'>
                          <div className='spread full-width'>
                            <input className='input px-2 text-sm' style={{height: '2rem', width: 60}} type="number" value={layer.metadata.size} onChange={e => changeLayerMetadata(layer, {size: e.target.value})}/>
                            <div className='flex-row flex-center'>
                              <div className='gray flex'><UpDownArrowIcon/></div>
                              <input className='input px-2 text-sm' style={{height: '2rem', width: 60}} type="number" value={layer.metadata.spacing} onChange={e => changeLayerMetadata(layer, {spacing: e.target.value})}/>
                            </div>
                            <div className='flex-row flex-center'>
                              <div className='gray flex'><UpDownArrowIcon rotate/></div>
                              <input className='input px-2 text-sm' style={{height: '2rem', width: 50}} type="number" value={layer.metadata.kerning} onChange={e => changeLayerMetadata(layer, {kerning: e.target.value})}/>
                            </div>
                          </div>
                        </div>
                      </>
                    :
                      <>
                        { isGroup(layer) ?
                          <div className='flex-row full-width spread'>
                            <div className='flex-row gap-1 gray'>
                              <FolderIcon/>Group ({layer.layers.length})
                            </div>
                            <div className='flex flex-row gap-1'>
                              <div className='relative'>
                                <button className={classNames('button tiny icon-button bg-f8f8f8', {'cursor-not-allowed': !canClickColorToCopy})} onClick={() => handleColorClick(layer.color)}>
                                  <div
                                    className="layer-color m-1px flex flex-center relative"
                                    style={{backgroundColor: layer.color}}
                                  >
                                    {hasNoColor(layer) && <div className='red text-center absolute-full opacity-50 bold' style={{fontSize: '1rem', lineHeight: '1.15rem'}}>X</div>}
                                  </div>
                                </button>
                                <div className='hover_parent_opacity pointer-events-none absolute right bold white bg-black-transparent rounded mt-1 px-2 p-1 nowrap text-md'>
                                  { hasNoColor(layer) ?
                                    <span>No Color (yet)</span>
                                  :
                                    <div> {layer.color?.toUpperCase()}
                                      { canClickColorToCopy ?
                                        <div className='not-bold'>
                                          Click to apply this color<br/>to the selected {[
                                            logoSelected && 'logo',
                                            textboxesSelected && 'textbox',
                                          ].filter(Boolean).join(' and ')}.
                                        </div>
                                      :
                                        null
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                              <div className='relative'>
                                <button className={classNames('button tiny icon-button', {'border-blue bg-light-blue': layer.brandable})} onClick={() => toggleBrandable(layer)}> <SwatchIcon/> </button>
                                <div className='hover_parent_opacity pointer-events-none absolute right bold white bg-black-transparent rounded mt-1 px-2 p-1 nowrap'> Brandable: {layer.brandable ? 'ON' : 'OFF'} {layer.brandable && <div className='not-bold'>This layer will be recolored<br/>to match the user's brand.</div>} </div>
                              </div>
                            </div>
                          </div>
                        : layer.type === 'video' ?
                          <div className='flex-row gap-1 gray'>
                            <VideoCameraIcon/>Video
                          </div>
                        :
                          <>
                            <div className='flex flex-row spread full-width'>
                              <div className='flex-row gap-1 gray'>
                                <PhotoIcon/>Image
                              </div>
                              <div className='flex flex-row gap-1'>
                                <div className='relative'>
                                  <button className={classNames('button tiny icon-button bg-f8f8f8', {'cursor-not-allowed': !canClickColorToCopy})} onClick={() => handleColorClick(layer.color)}>
                                    <div
                                      className="layer-color m-1px flex flex-center relative"
                                      style={{backgroundColor: layer.color}}
                                    >
                                      {hasNoColor(layer) && <div className='red text-center absolute-full opacity-50 bold' style={{fontSize: '1rem', lineHeight: '1.15rem'}}>X</div>}
                                    </div>
                                  </button>
                                  <div className='hover_parent_opacity pointer-events-none absolute right bold white bg-black-transparent rounded mt-1 px-2 p-1 nowrap text-md'>
                                    { hasNoColor(layer) ?
                                      <span>No Color (yet)</span>
                                    :
                                      <div> {layer.color?.toUpperCase()}
                                        { canClickColorToCopy ?
                                          <div className='not-bold'>
                                            Click to apply this color<br/>to the selected {[
                                              logoSelected && 'logo',
                                              textboxesSelected && 'textbox',
                                            ].filter(Boolean).join(' and ')}.
                                          </div>
                                        :
                                          null
                                        }
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div className='relative'>
                                  <button className={classNames('button tiny icon-button', {'border-blue bg-light-blue': layer.brandable})} onClick={() => toggleBrandable(layer)}> <SwatchIcon/> </button>
                                  <div className='hover_parent_opacity pointer-events-none absolute right bold white bg-black-transparent rounded mt-1 px-2 p-1 nowrap'> Brandable: {layer.brandable ? 'ON' : 'OFF'} {layer.brandable && <div className='not-bold'>This layer will be recolored<br/>to match the user's brand.</div>} </div>
                                </div>
                              </div>
                            </div>
                            <div className='select full-width'>
                              <select className='full-width' value={layer.type} onChange={setType}>
                                <option value="">Image Type (optional)</option>
                                <option value="background">Background</option>
                                <option value="photo">Photo</option>
                                <option value="text">Text (Static)</option>
                              </select>
                            </div>
                          </>
                        }
                      </>
                    }
                    <div className='hidden'>
                      {Math.round(layer.width)} x {Math.round(layer.height)}
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </div>

        { colorResetPosition && <>
          <div className='fixed z-1' style={{
            top:  colorResetPosition.y + (safari ? 30 : 45),
            left: colorResetPosition.x - (safari ? 90 : 10),
          }}>
            <Popup visible >
              <div className='p-4 bg-white rounded text-sm dark-gray flex-col gap-3' style={{maxWidth:250}}>
                <p>
                  If you pick a color for the logo layer, and the user has a logo
                  with the "Colorized" style, their logo will be recolored to
                  match the selected color.
                </p>
                <p>
                  <button className='link no-border p-0 bg-none text-sm' style={{color: 'red'}} onClick={handleRemoveLogoColor}>
                  Remove the color from this logo</button> if&nbsp;a&nbsp;semi-transparent logo
                  does NOT look good for this image.
                </p>
              </div>
            </Popup>
          </div>
        </>}

        <Modal visible={showUpload} onClickOutside={() => setShowUpload(false)}>
          <div className='padded content center-text' style={{width: '80vw', maxWidth: 400}}>
            <h2>Upload an Image</h2>
            <FileUploader onUpload={handleUpload} multiple/>
            <div className="button-container" style={{marginTop: '1rem'}}>
              <button className="button" onClick={() => setShowUpload(false)}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>


        <Modal visible={shortcutsVisible} onClickOutside={toggleShortcuts}>
          <div className='pt-4 pb-5 px-5'>
            <div className='flex-row spread' style={{marginRight: '-1rem'}}>
              <h2 className='text-xl'>
                Keyboard Shortcuts
              </h2>
              <button className='button no-border is-inverted' onClick={toggleShortcuts}>
                <CloseIcon/>
              </button>
            </div>
            <div className='shortcut-list border mt-4 bg-eee p-4'>
              <span><b>Multi-Select Layers:</b></span>
              <span>Shift + Click </span>

              <span><b>Toggle Layer Selection:</b></span>
              <span>{ctrlKeyName} + Click </span>

              <span><b>Deselect All Layers:</b></span>
              <span>{ctrlKeyName} + D</span>

              <span><b>Finish Editing Text:</b></span>
              <span>Esc <span className="gray">or</span> {ctrlKeyName} + Enter</span>

              <span><b>Delete Selected Layers:</b></span>
              <span>Backspace <span className="gray">or</span> Delete</span>

              <span><b>Undo Last Change:</b></span>
              <span>{ctrlKeyName} + Z </span>

              <span><b>Redo (after undo):</b></span>
              <span>{ctrlKeyName} + Shift + Z </span>

              <span><b>Group Selected Layers:</b></span>
              <span>{ctrlKeyName} + G </span>

              <span><b>Ungroup Selected Layer:</b></span>
              <span>{ctrlKeyName} + Shift + G </span>

              <div className='bg-ddd' style={{height: 1}}></div>
              <div className='bg-ddd' style={{height: 1}}></div>

              <span><b>Prevent Snapping:</b></span>
              <span>{ctrlKeyName} <span className='gray'>(while moving layer)</span> </span>

              <span><b>Symetrical Resize:</b></span>
              <span>Alt <span className='gray'>(while resizing layer)</span> </span>
            </div>
          </div>
        </Modal>

        { contentMenuPosition && <>
          <div className='fixed' style={{top: contentMenuPosition.y, left: contentMenuPosition.x}}>
            <Popup visible onClickOutside={() => setContentMenuPosition(null)}>
              <div className='p-2 bg-white rounded'>
                {contextMenu}
              </div>
            </Popup>
          </div>
        </>}
      </div>
    </FileUploader>

    <style jsx>{`
      ${fonts.map(font => {
        if (font.url) {
          return `@font-face { font-family: '${font.uid}'; src: url('${font.url}') format('truetype'); }`
        }
      }).filter(Boolean).join('\n')}
    `}</style>

    <style jsx>{`
      :global(.toolbar-button) {
        height: 36px !important;
        max-height: 36px !important;
        min-width: 36px !important;
      }
      :global(.toolbar-button:hover) {
        background-color: #3499CC22;
        z-index: 1;
      }
      .button[disabled] {
        opacity: 0.3;
      }
      .shortcut-list {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 0.5rem 1rem;
        width: fit-content;
        color: #333;
        white-space: nowrap;
        z-index: 99;
      }
      .shortcut-list > span:nth-child(2n) {
        text-align: left;
      }
      .action-menu-button {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        display: flex;
      }
      .popup {
        position: absolute;
        right: 2rem;
        top: -0.5rem;
        display: none;
        background-color: white;
        padding: 1.5rem;
        border-radius: 8px;
        width: fit-content;
        box-shadow: 0 0 8px rgba(0 0 0 / 0.5);
        color: #333;
        z-index: 99;
      }
      .image-panel {
        display: flex;
        align-items: flex-start;
      }
      .image-preview {
        position: relative;
        flex: auto;
        overflow: hidden;
      }
      .preview-branding .brandable .logo-preview,
      .preview-branding .brandable .textbox-preview,
      .preview-branding .brandable .logo-area,
      .preview-branding .layer-widget.brandable img,
      .preview-branding .layer.brandable img {
        filter: hue-rotate(90deg);
      }
      .color-picker {
        width: 28px;
      }
      .disabled .color-picker {
        opacity: 0.2;
      }
      .layer-color {
        height: 20px;
        width: 20px;
        border-radius: 100%;
        display: inline-block;
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 2px rgb(0 0 0 / 33%);
        position: relative;
      }
      .layer-color.no-color:after {
        content: '?';
        font-size: 0.9rem;
        color: red;
        position: absolute;
        margin-left: 0.4rem;
      }
      .layer-panel {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        margin-left: 1rem;
        overflow-y: auto;
        max-height: 100%;
      }
      .layers-list {
        flex: none;
        max-height: 100%;
        overflow-y: auto;
        padding: 0.5rem 0.5rem;
        margin:  -0.5rem -0.5rem;
        box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
        background-color: white;
        border-radius: 8px;
        border: 1px solid #ddd;
      }
      .layer-widget {
        display: flex;
        padding-bottom: 0.5rem;
        align-items: stretch;
      }
      .layer-drag-in-progress .layer-widget {
        transition: all 200ms;
      }
      .layer-widget.dragging {
        opacity: 0;
      }
      .layer-widget.slide-up {
        transform: translateY(-100%)
      }
      .layer-widget.slide-down {
        transform: translateY(100%)
      }
      .layer-controls {
        min-width: 185px;
        border: 1px solid #ddd;
      }
      .delete-layer {
        /* position: absolute;
        right: -4px;
        top: -4px; */
      }
      .image-actions {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0px;
        align-items: center;
        justify-content: center;
        transition: left 100ms 300ms;
      }
      .layer-widget:hover .image-actions {
        transition: left 300ms 100ms;
        left: -16px;
      }
      .image-action {
        cursor: pointer;
        color: #333;
        font-size: 0.8rem;
        padding: 0.25rem 0.5rem;
        margin: 0.1rem;
        background-color: white;
        border: 1px solid rgba(0, 0, 255, 0.5);
        opacity: 0;
        z-index: 1;
        display: flex;
        border-radius: 4px;
        transition: opacity 300ms;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
      }
      .layer-thumb:hover .image-action {
        opacity: 0.9;
        transition: opacity 300ms 100ms;
      }
      .layer-thumb:hover .img-wrapper {
        outline: 4px solid rgba(255, 89, 0, 0.2);
        border-radius: 4px;
        z-index: 1;
      }
      .image-action:hover {
        opacity: 1 !important;
        background-color: blue;
        color: white;
      }
      .delete-layer {
        padding: 0.25rem 0.7rem;
        border: 1px solid rgba(255, 0, 0, 0.5);
      }
      .delete-layer:hover {
        background-color: red;
      }
      .delete-layer:hover :global(.icon) {
        color: white;
      }
      .layer {
        position: absolute;
        display: grid;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .center-line:before,
      .center-line {
        border-left: 1px solid rgb(231, 0, 0);
        left: calc(50% - 1px);
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        z-index: 1;
      }
      .center-line:before {
        border-left: 3px solid rgba(255, 255, 255, 0.4);
        left: calc(50% - 2px);
      }
      .middle-line:before,
      .middle-line {
        border-top: 1px solid rgb(231, 0, 0);
        top: calc(50% - 1px);
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        z-index: 1;
      }
      .middle-line:before {
        border-top: 3px solid rgba(255, 255, 255, 0.4);
        top: calc(50% - 2px);
      }
      .layer.draggable {
        cursor: move;
        cursor: grab;
      }
      .drag-in-progress .layer.draggable {
        cursor: grabbing !important;
      }
      .layer .layer-contents {
        position: relative;
        user-select: none;
      }
      .thumbnail-image {
        position: relative;
        user-select: none;
      }
      .layer:first-child {
        position: relative;
      }
      .image-preview .layer.inactive {
        opacity: ${inactiveLayerOpacity};
        pointer-events: none;
      }
      .layer-thumb {
        position: relative;
      }
      .layer-thumb .logo-area,
      .layer-thumb .img-wrapper .layer-contents {
        display: block;
        position: relative;
      }
      .layer-thumb .img-wrapper {
        cursor: pointer;
        line-height: 0;
        overflow: hidden;
      }
      .image-preview,
      .layer-thumb .img-wrapper {
        position: relative;
        outline: 1px solid #ddd;
      }
      .image-preview::before,
      .layer-thumb .img-wrapper::before {
        content: "";
        background-image: url('/img/grid.png');
        background-repeat: repeat;
        background-size: 16px auto;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 1px;
        left: 0px;
        filter: brightness(${parseFloat(canvasBrightness) + 0.15});
        outline: 1px solid #ddd;
      }
      .layer-thumb .img-wrapper::before {
        background-size: 8px auto;
      }
      .layer-widget.active .layer-thumb {
        z-index: 1;
      }
      .layer-widget.inactive .img-wrapper {
        opacity: 0.3;
      }
      .layer-widget.inactive .img-wrapper:hover {
        opacity: 0.8;
      }
      .layer-widget.active .img-wrapper {
        outline: 3px dashed rgba(255, 89, 0, 0.7);
        outline-offset: 2px;
        border-radius: 4px;
      }
      .layer-thumb:last-child .layer-contents {
        margin-bottom: 0;
      }
      .selectable {
        cursor: pointer;
      }
      .selectable:hover::before,
      .selectable:hover::after,
      .layer.selected::before,
      .layer.selected::after {
        content: "";
        position: absolute;
        outline-width: 2px;
        outline-style: dashed;
        outline-color: #000000AA;
        outline-offset: -1px;
        top:     0;
        left:    0;
        right:   0;
        bottom:  0;
        z-index: 1;
        pointer-events: none;
      }
      .selectable:hover::before,
      .layer.selected::before {
        outline-color: #FFFFFFDD;
        outline-style: solid;
      }
      .selectable:hover::before {
        outline-color: #00000066;
      }
      .selectable:hover::after {
        outline-color: #FFFFFFAA;
      }

    `}</style>
  </>
}


function AlignmentControl({alignX, alignY, onChange, disabled, onClose, onOpen}) {
  const alignment = [alignY, alignX].join('-')

  const [hovering, setHovering] = useState('')

  const labelAlignment = hovering || alignment
  const [labelY, labelX] = labelAlignment.split('-')
  let label = 'Align'

  if (labelAlignment) {
    if (labelAlignment === 'middle-center') label = 'Center'
    else if (labelX === 'center')           label = titleize(labelY)
    else if (labelY === 'middle')           label = titleize(labelX)
    else                                    label = titleize(labelY) + ' ' + titleize(labelX)
  }

  function handleChange (newAlignment) {
    const newAlignX = newAlignment.split('-')[1]
    const newAlignY = newAlignment.split('-')[0]

    onChange({alignX: newAlignX, alignY: newAlignY})
  }

  return <>
    <PopupMenu
      className="toolbar-button"
      small
      icon={<AlignmentIcon/>}
      disabled={disabled}
      onOpen={onOpen}
      onClose={onClose}
      tooltip="Alignment"
    >
      <div className='flex flex-col gap-1'>
        <div className='grid grid-cols-3'>
          <button className={classNames('button tiny icon-button no-shadow hover_bg-light-blue', {'bg-light-blue': alignment === 'top-left'       })} onPointerEnter={() => setHovering('top-left'       )} onPointerLeave={() => setHovering('')} onClick={() => handleChange('top-left')}> <AlignmentIcon value="top-left"/> </button>
          <button className={classNames('button tiny icon-button no-shadow hover_bg-light-blue', {'bg-light-blue': alignment === 'top-center'     })} onPointerEnter={() => setHovering('top-center'     )} onPointerLeave={() => setHovering('')} onClick={() => handleChange('top-center')}> <AlignmentIcon value="top-center"/> </button>
          <button className={classNames('button tiny icon-button no-shadow hover_bg-light-blue', {'bg-light-blue': alignment === 'top-right'      })} onPointerEnter={() => setHovering('top-right'      )} onPointerLeave={() => setHovering('')} onClick={() => handleChange('top-right')}> <AlignmentIcon value="top-right"/> </button>
          <button className={classNames('button tiny icon-button no-shadow hover_bg-light-blue', {'bg-light-blue': alignment === 'middle-left'    })} onPointerEnter={() => setHovering('middle-left'    )} onPointerLeave={() => setHovering('')} onClick={() => handleChange('middle-left')}> <AlignmentIcon value="middle-left"/> </button>
          <button className={classNames('button tiny icon-button no-shadow hover_bg-light-blue', {'bg-light-blue': alignment === 'middle-center'  })} onPointerEnter={() => setHovering('middle-center'  )} onPointerLeave={() => setHovering('')} onClick={() => handleChange('middle-center')}> <AlignmentIcon value="middle-center"/> </button>
          <button className={classNames('button tiny icon-button no-shadow hover_bg-light-blue', {'bg-light-blue': alignment === 'middle-right'   })} onPointerEnter={() => setHovering('middle-right'   )} onPointerLeave={() => setHovering('')} onClick={() => handleChange('middle-right')}> <AlignmentIcon value="middle-right"/> </button>
          <button className={classNames('button tiny icon-button no-shadow hover_bg-light-blue', {'bg-light-blue': alignment === 'bottom-left'    })} onPointerEnter={() => setHovering('bottom-left'    )} onPointerLeave={() => setHovering('')} onClick={() => handleChange('bottom-left')}> <AlignmentIcon value="bottom-left"/> </button>
          <button className={classNames('button tiny icon-button no-shadow hover_bg-light-blue', {'bg-light-blue': alignment === 'bottom-center'  })} onPointerEnter={() => setHovering('bottom-center'  )} onPointerLeave={() => setHovering('')} onClick={() => handleChange('bottom-center')}> <AlignmentIcon value="bottom-center"/> </button>
          <button className={classNames('button tiny icon-button no-shadow hover_bg-light-blue', {'bg-light-blue': alignment === 'bottom-right'   })} onPointerEnter={() => setHovering('bottom-right'   )} onPointerLeave={() => setHovering('')} onClick={() => handleChange('bottom-right')}> <AlignmentIcon value="bottom-right"/> </button>
        </div>
        <div className='text-center dark-gray'>
          {label}
        </div>
      </div>
    </PopupMenu>
  </>
}


function ShadowPicker ({ shadow, colorEditable, onChange, onOpen, onClose}) {

  const [showSliders, setShowSliders] = useState(false)

  return (
    <PopupMenu
      className="toolbar-button"
      icon={
        <div
          className='text-lg default-text-color white bold'
          style={{
            filter: `drop-shadow(rgba(0, 0, 0, 0.4) 7px 5px 1px)`,
            transform: `translate(-2px, -2px)`,
          }}
        >
          S
        </div>
      }
      small
      onOpen={onOpen}
      onClose={onClose}
      tooltip="Shadow"
    >
      <div className='p-2 flex-col gap-2'>
        <div className='flex-col'>
          <div className='bold flex-row relative'>
            <button className={classNames('small button', {'bg-light-blue': !shadow})} onClick={() => {onChange(null); setShowSliders(false)}}>None</button>
            <button className={classNames('small button', {'bg-light-blue': shadow?.color === '#000000'})} onClick={() => onChange(defaultShadow)}>Shadow</button>
            <button className={classNames('small button', {'bg-light-blue': shadow?.color === '#FFFFFF'})} onClick={() => onChange(defaultGlow)}>Glow</button>
            <button className='small button is-inverted no-border px-2' onClick={() => {
              if (!shadow) onChange(defaultShadow)
              setShowSliders(v => !v)
            }}>
              <EditIcon/>
            </button>
          </div>
          <div className='relative' style={{maxHeight: showSliders ? 200 : 0, overflow: 'hidden', transition: 'max-height 300ms ease-in-out'}}>
            <div className='grid grid-cols-2 gap-2 px-2 mt-4'>
              <label>Horizonal</label>
              <input
                type="range"
                value={(shadow || defaultShadow).x}
                min="-20"
                max="20"
                onChange={e => onChange({...shadow, x: e.target.value})}
              />

              <label>Vertical</label>
              <input
                type="range"
                value={(shadow || defaultShadow).y}
                min="-20"
                max="20"
                onChange={e => onChange({...shadow, y: e.target.value})}
              />

              <label>Opacity</label>
              <input
                type="range"
                value={(shadow || defaultShadow).opacity}
                min="0"
                max="100"
                onChange={e => { onChange({...shadow, opacity: e.target.value}) }}
              />

              <label>Blur</label>
              <input
                type="range"
                value={(shadow || defaultShadow).blur}
                min="0"
                max="20"
                onChange={e => onChange({...shadow, blur: e.target.value})}
              />

              { colorEditable  &&
                <>
                  <label>Color</label>
                  <input
                    type="color"
                    value={(shadow || defaultShadow).color}
                    onChange={e => { onChange({...shadow, color: e.target.value}) }}
                  />
                </>
              }

            </div>
          </div>
        </div>

      </div>

    </PopupMenu>
  )
}


function FontPicker({fonts, uid, size, onChangeFont, onChangeSize, onOpen, onClose}) {
  const currentFont = fonts.find(font => font.uid === uid)

  const indexOfRegularWeight = fontWeights.indexOf('Regular')

  const [visible, setVisible] = useState(false)
  const [weightIndex, setWeightIndex] = useState(indexOfRegularWeight)
  const [changingFontSize, setChangingFontSize] = useState(false)


  useEffect(() => {
    if (!currentFont) return
  }, [weightIndex])


  useEffect(() => {
    if (visible) {
      onOpen && onOpen()
      const currentWeightIndex = weightIndexOf(currentFont)
      const defaultWeightIndex = Number.isInteger(currentWeightIndex) ? currentWeightIndex : indexOfRegularWeight
      setWeightIndex(defaultWeightIndex)

      const currentFontElement = document.getElementsByClassName('selected-font')[0]
      if (currentFontElement) {
        currentFontElement.scrollIntoView({block: 'center'})
      }
    } else {
      onClose && onClose()
    }
  }, [visible])


  if (!currentFont) return null

  const families = uniq(fonts.map(font => font.family))
  const fontsInSameFamilyAndStyle = fontsIn(currentFont.family).filter(font => isItalic(font) === isItalic(currentFont))

  const fontsToShow = flatten(families.map(fam => {
    const nonItalicFonts = fontsIn(fam).filter(font => !isItalic(font))
    const italicFonts = fontsIn(fam).filter(isItalic)

    const matches = [
      closestFontByWeightIndex(nonItalicFonts, weightIndex),
      closestFontByWeightIndex(italicFonts, weightIndex),
    ].filter(Boolean)

    return sortBy(matches, niceName)
  })).filter(Boolean)


  function fontsIn(fam) {
    if (!fam) return []
    return fonts.filter(font => font.family === fam)
  }

  function isItalic(font)  {
    if (!font) return false
    return font.name.endsWith('Italic')
  }

  function weightOf(font) {
    if (!font) return ''
    const fontStyle = font.name.split('-')[1]
    if (!fontStyle) return 'Regular'
    return fontStyle.replace('Italic', '').trim() || 'Regular'
  }

  function weightIndexOf(font) {
    if (!font) return 0
    return fontWeights.indexOf(weightOf(font))
  }

  function closestFontByWeightIndex(fontList, index) {
    const list = sortBy(fontList, weightIndexOf)
    if (index >= indexOfRegularWeight) {
      return list.reverse().find(font => weightIndexOf(font) <= index)
    } else {
      return list.find(font => weightIndexOf(font) >= index)
    }
  }

  function handleWeightChange(e) {
    const newIndex = parseInt(e.target.value, 10)
    if (isNaN(newIndex)) return
    setWeightIndex(newIndex)

    if (!currentFont) return

    const newFont = closestFontByWeightIndex(fontsInSameFamilyAndStyle, newIndex)
    if (newFont) {
      onChangeFont(newFont.uid)
    }
  }

  function handleChangeFont(e) {
    const newUid = e.currentTarget.value
    onChangeFont(newUid)
  }

  function handleFontSizeChange(e) {
    let newSize = e.target.value
    newSize = parseInt(newSize, 10)
    if (isNaN(newSize)) newSize = size
    if (newSize < 1) newSize = 1
    onChangeSize(newSize)
  }

  function niceName(font) {
    let name = font.family
    if (isItalic(font)) name += ' Italic'
    return name
  }

  return <>
    <div className='relative'>
      <button
        className='toolbar-button button px-0'
        onClick={() => setVisible(v => !v)}
      >
        <FontIcon/>
      </button>

      <div className='hover-parent z-3 pointer-events-none absolute mt-1'>
        <div className='py-1 px-3 text-sm bold white bg-black-transparent rounded nowrap'>
          Font
        </div>
      </div>

      <Popup visible={visible} onClickOutside={() => setVisible(false)}>
        <div className='flex-col gap-2 p-3 min-width-300'>

          <div>
            <div className='flex-row spread'>
              <label>Size</label>
              <div className='gray text-sm'>
                <span>{size}px</span>
              </div>
            </div>

            <input
              className='full-width'
              type="range"
              min="40"
              max="400"
              value={size}
              onChange={handleFontSizeChange}
              onPointerDown={() => setChangingFontSize(true)}
              onPointerUp={() => setChangingFontSize(false)}
            />
          </div>

          <div className={classNames({hidden: changingFontSize})}>

            { fontWeights.length > 0 &&
              <div>
                <div className='flex-row spread'>
                  <label>Weight</label>
                  <div className='gray text-sm'>
                    { fontsInSameFamilyAndStyle.length <= 1 ?
                      <span>Unavailable for current font</span>
                    :
                      <span>{fontWeights[weightIndex]}</span>
                    }
                  </div>
                </div>
                <input
                  className='full-width'
                  type="range"
                  min="0"
                  max={fontWeights.length - 1}
                  value={weightIndex}
                  onChange={handleWeightChange}
                />
              </div>
            }

            <div className='relative my-2'>
              { fontsToShow.length === 0 ?
                <div className='flex-row flex-align-center justify-center p-2 gray border border-gray rounded'>
                  No fonts found
                </div>
              :
                <>
                  <div className='rounded z-1 absolute-full shadow-inset-xl pointer-events-none'></div>
                  <div className='rounded flex-col relative overflow-auto-y border bg-white' style={{maxHeight: '50vh'}}>
                    {fontsToShow.map(font => {
                      const isSelected = niceName(font) === niceName(currentFont)
                      return (
                        <button
                          key={font.uid}
                          onClick={() => handleChangeFont({currentTarget: {value: font.uid}})}
                          style={{fontFamily: font.uid, marginTop: '-1px', fontSize: '1.2rem', minHeight: '2.5rem', width: '100%', maxWidth: 280}}
                          className={classNames(
                            'default-text-color text-left flex-row flex-align-center nowrap p-2 border-gray hover_bg-light-blue overflow-hidden',
                            {'bg-light-blue': isSelected, 'bg-white': !isSelected, 'selected-font': isSelected},
                          )}
                          title={niceName(font)}
                        >
                          {niceName(font)}
                        </button>
                      )
                    })}
                  </div>
                </>
              }
            </div>
          </div>

        </div>
      </Popup>
    </div>
  </>
}


// Grouped layers may have non-consecuitive z values, which is not pretty.
// Recently ungrouped layers will have z values with decimals (to keep them
// together in the z-stack when merged with the other root-level layers). This
// function will convert the z values to consecutive integers, while maintaining
// the correct z order of the layers.
function zValueCleanup(layerList) {
  return sortBy(layerList, 'z').map((l, i) => ({...l, z: i}))
}


function hexToRgba(hex, opacity) {
  hex = hex.replace(/^#/, '')

  let r, g, b

  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16)
    g = parseInt(hex[1] + hex[1], 16)
    b = parseInt(hex[2] + hex[2], 16)
  } else if (hex.length === 6) {
    r = parseInt(hex.slice(0, 2), 16)
    g = parseInt(hex.slice(2, 4), 16)
    b = parseInt(hex.slice(4, 6), 16)
  }

  const alpha = opacity / 100
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
