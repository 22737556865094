import useDeviceInfo from "@/src/useDeviceInfo"
import useOnClickOutside from "@/src/useOnClickOutside"
import classNames from "classnames"
import { useState, useEffect, useRef } from "react"
import { createPortal } from "react-dom"


export default function Popup ({ visible, children, onClickOutside }) {

  const locationRef = useRef()
  const popupBodyRef = useRef()
  const [positioned, setPositioned] = useState(false)

  const {mobile} = useDeviceInfo()

  useEffect(() => {
    if (!visible) setPositioned(false)
  }, [visible])


  useOnClickOutside(popupBodyRef, e =>  {
    // Slight delay, so a click on the original element that opened the popup
    // doesn't re-open the popup immediately after closing it.
    if (onClickOutside && visible) setTimeout(() => onClickOutside(e), 0)
  })


  const [style, setStyle] = useState({})
  const horizontalScroll = !!style.maxWidth
  const verticalScroll = !!style.maxHeight


  // When things scroll or resize, reposition the popup (and resize if needed)
  useEffect(() => {
    if (!locationRef.current || !popupBodyRef.current) return

    positionMenu()

    const resize = throttle(positionMenu, 250) //ms between updates

    const resizeObserver = new ResizeObserver(resize)

    const popupBody = popupBodyRef.current
    window.addEventListener('resize', resize)
    window.addEventListener('scroll', resize)
    window.document.querySelector('.scrollable-shell')?.addEventListener('scroll', resize)

    if (popupBody) {
      resizeObserver.observe(popupBody)
    }

    return () => {
      window.removeEventListener('resize', resize)
      window.removeEventListener('scroll', resize)
      window.document.querySelector('.scrollable-shell')?.addEventListener('scroll', resize)
      resizeObserver.unobserve(popupBody)
    }

    function positionMenu () {
      if (!visible) return
      if (!locationRef.current || !popupBodyRef.current) return

      const popupStyle = {position: 'absolute'}

      const rect = locationRef.current.getBoundingClientRect()
      const {top, left, right, bottom} = rect
      const {innerWidth, innerHeight} = window

      const popupWidth = popupBodyRef.current.scrollWidth
      const popupHeight = popupBodyRef.current.scrollHeight

      const margin = mobile ? 8 : 40
      const borderWidth = 1 // Needed to prevent extraneous scrollbars

      const spaceBelow = innerHeight - bottom - margin
      const spaceAbove = top - margin
      const spaceRight = innerWidth - right - margin
      const spaceLeft  = left - margin

      const scrollbarWidth = innerWidth - document.documentElement.clientWidth

      if (spaceAbove < popupHeight && spaceBelow < popupHeight) {
        const height = Math.min(popupHeight + borderWidth * 2, innerHeight - scrollbarWidth - margin * 2)
        popupStyle.top = margin + 'px'
        popupStyle.height = height
        popupStyle.maxHeight = height
      } else if (spaceBelow >= spaceAbove) {
        popupStyle.top = `${bottom}px`
      } else {
        popupStyle.top = `${top - popupHeight}px`
      }

      if (spaceLeft < popupWidth && spaceRight < popupWidth) {
        const width = Math.min(popupWidth + borderWidth * 2, innerWidth - scrollbarWidth - margin * 2)
        popupStyle.left = margin + 'px'
        popupStyle.width = width
        popupStyle.maxWidth = width
      } else if (spaceRight >= spaceLeft) {
        popupStyle.left = `${left}px`
      } else {
        popupStyle.left = `${left - popupWidth + rect.width}px`
      }

      setStyle(popupStyle)
      setPositioned(true)
    }

  }, [visible])


  // Use a portal to render the popup outside the normal DOM hierarchy so
  // that it can be above other elements on the page, not cropped by overflow
  // hidden, and not affected by z-index of other elements.
  function portal (children) {
    return createPortal(children, document.body)
  }

  return <>
    <div className="popup-location absolute-full pointer-events-none z-down" ref={locationRef}>
    </div>

    {
      portal(
        <>
          <div
            className={classNames("popup-wrapper", {hidden: !visible, 'pointer-events-none': !visible, ready: (visible && positioned)})}
            style={style}
            aria-modal={true}
            tabIndex={-1}
            role="dialog"
          >
            <div className="popup-body" ref={popupBodyRef}>
              {children}
            </div>
          </div>

          <style jsx>{`
            .popup-wrapper {
              z-index: 1000;
              position: relative;
              opacity: 0;
              transition: opacity 150ms;
              display: block;
              outline: 0;
              width: fit-content;
              overflow: hidden;
              box-sizing: border-box;
              border-radius: 0.25rem;
              border: 1px solid #ddd;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.1);
            }

            .popup-wrapper.ready {
              opacity: 1;
            }

            .popup-body {
              background: #fff;
              border-radius: 0.25rem;
              display: block;
              margin: 0;
              max-height: 100%;
              overflow-y: ${verticalScroll ? 'auto' : 'hidden'};
              overflow-x: ${horizontalScroll ? 'auto' : 'hidden'};
              position: relative;
            }

          `}</style>
        </>
      )
    }
  </>
}


function throttle(fn, delay) {
  let timer
  let pending = false

  return function () {
    if (timer) {
      pending = true
      return
    }

    fn()

    timer = setTimeout(() => {
      timer = null
      if (pending) {
        pending = false
        fn()
      }
    }, delay)
  }
}